export type SingleSubResourceParams = ScenarioParentage &
  HasDataType &
  HasDetailId &
  HasSubResource &
  HasId;
export type ListSubResourcesParams = ScenarioParentage &
  HasDataType &
  HasDetailId &
  HasSubResource;
export type Endpoints = Record<string, Record<string, string>>;

export interface ScenarioParentage {
  readonly project: string;
  readonly case: string;
  readonly scenario: string;
}

export type AffectedEntitiesDetailsKeysType = ScenarioParentage &
  HasDataType &
  HasDetailId & { readonly operationalType: 'delete' | 'update' };

interface HasDataType {
  readonly dataType: string;
}

interface HasDetailId {
  readonly detailId: string;
}

interface HasId {
  readonly id: string;
}

interface HasSubResource {
  readonly subResource: string;
}
