@if (showEdit()) {
  <div class="form-field-container">
    <mat-form-field
      appearance="outline"
      subscriptSizing="dynamic"
      class="custom-form-field"
    >
      <input
        matInput
        type="text"
        [formControl]="ctrl"
        title="{{ ctrl.value }}"
        data-testid="cell-editable-input-value"
      />
      @if (ctrl.invalid) {
        <mat-icon
          matSuffix
          color="warn"
          class="icon-error"
          [matTooltip]="
            'Scenario.messages.name.' + showError(ctrl.errors) + '-tooltip'
              | translate
          "
          >error</mat-icon
        >
      } @else {
        <button
          matSuffix
          mat-icon-button
          color="primary"
          aria-label="Save"
          type="button"
          class="value-btn value-btn-save"
          (click)="onSaveChange()"
          data-testid="cell-editable-btn-save"
        >
          <mat-icon>save</mat-icon>
        </button>
      }
    </mat-form-field>
    <button
      mat-icon-button
      color="primary"
      type="button"
      aria-label="Close"
      class="value-btn value-btn-close"
      (click)="toggleEdit(false, true)"
      data-testid="cell-editable-btn-cancel"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
} @else {
  <div class="value-container">
    @if (ctrl.invalid) {
      <mat-icon
        color="warn"
        class="icon-error"
        [matTooltip]="
          'Scenario.messages.name.' + showError(ctrl.errors) + '-tooltip'
            | translate
        "
        >error</mat-icon
      >
    }
    <span
      class="value-text"
      title="{{ savedValue() }}"
      data-testid="cell-editable-text-value"
      >{{ savedValue() }}</span
    >
    <button
      mat-icon-button
      color="primary"
      type="button"
      aria-label="Edit"
      class="value-btn value-btn-edit"
      [disabled]="disabled"
      (click)="toggleEdit(true)"
      data-testid="cell-editable-btn-edit"
    >
      @if (disabled) {
        <mat-icon class="disabled-icon" aria-hidden="false">edit_off</mat-icon>
      } @else {
        <mat-icon color="primary" aria-hidden="false">edit</mat-icon>
      }
    </button>
  </div>
}
