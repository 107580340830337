import { StationVehicleAssoc as OldMobilityStation } from 'prosumer-app/+scenario/models';
import { DetailEntity, ScenarioDetailState } from '../scenario-detail';

export type MobilityStation = OldMobilityStation & DetailEntity;
export interface MobilityStationBE extends DetailEntity {
  readonly stationId: string;
  readonly vehicleIds: Array<string>;
  readonly vehiclesDispatchIds: Array<string>;
  readonly stationNodes: Array<string>;
}

export type MobilityStationState = ScenarioDetailState<MobilityStation>;
