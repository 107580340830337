import { ActiveState, EntityState } from '@datorama/akita';

export interface ScenarioGeneric {
  readonly projectUuid: string;
  readonly caseUuid: string;
  readonly scenarioUuid: string;
  readonly name: string;
  readonly owner: string;
  readonly economics: ScenarioEconomics;
  readonly projectDuration: ProjectDuration;
  readonly fullTimeHorizon: boolean;
  // TODO: remove when data has been migrated
  readonly timeHorizon: TimeHorizon;
  readonly yearsToOptimize: YearsToOptimize;
  readonly emissions: Emissions;
  readonly limits: Limits;
  readonly options: OptimizationOptions;
  readonly updatedAt?: string;
}

export type ScenarioUpdateKey = keyof ScenarioGeneric;

export interface Emissions {
  readonly co2Price?: YearValues;
  readonly co2Scope2Price?: YearValues;
  readonly co2Scope3Price?: YearValues;
}

export interface Limits {
  readonly yearlyMaximumEmissions?: YearValues;
  readonly yearlyMaximumCosts?: YearValues;
  readonly globalMaximumEmissions?: string;
  readonly globalMaximumCosts?: string;
}

export interface OptimizationOptions {
  readonly mipGap?: string;
  readonly limitRunHour?: string;
  readonly preventSimultaneousCharge?: number;
  readonly lcox?: number;
}

export interface ScenarioEconomics {
  readonly wacc: number;
}
export interface ProjectDuration {
  readonly startYear: number;
  readonly duration: number;
}

export interface YearsToOptimize {
  years: number[];
  defaultYear: number;
}
// TODO: remove when data has been migrated
export interface TimeHorizon {
  fullTimeHorizon: boolean;
}

export interface ScenarioDetailInfo<T> {
  readonly scenarioUuid: string;
  readonly dataType: string;
  readonly details: T[];
}

export interface ScenarioGenericState
  extends EntityState<ScenarioGeneric, string>,
    ActiveState {
  readonly latestModifiedIds: string[];
  readonly loading: boolean;
  readonly error: unknown;
}

export interface YearValues {
  readonly values: string[];
  readonly year?: number;
}

export const initializeState = (): ScenarioGenericState => ({
  entities: {},
  ids: [],
  loading: false,
  error: null,
  latestModifiedIds: [],
  active: null,
});
