export type FormMode = 'add' | 'edit';

export interface Netting {
  readonly id: string;
  readonly name: string;
  readonly timePartition: string;
  readonly energyVectorId: string;
  readonly nodes: Array<string>;
  readonly equipments?: Array<string>;
  readonly loads?: Array<string>;
}

export interface TaxAndSubsidies {
  readonly id: string;
  readonly netting: string;
  readonly nodes: Array<string>;
  readonly taxSubsidyIntervals?: Array<TaxSubsidyInterval>;
}

export interface TaxSubsidyInterval {
  readonly generationTariffSubsidy?: string;
  readonly autoConsumptionTax?: string;
  readonly feedInTariffSubsidy?: string;
  readonly startYear?: number;
  readonly endYear?: number;
  readonly yearOptions?: Array<number>;
}

export interface NettingForm {
  readonly netting?: Array<Netting>;
  readonly taxAndSubsidies?: Array<TaxAndSubsidies>;
}
