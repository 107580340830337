import {
  arrayToString,
  hasMultipleValues,
  ManagedTableComponent,
  rowAnimation,
} from 'prosumer-app/libs/eyes-shared';
import { ManagedDataService } from 'prosumer-app/shared/services/managed-data';
import { YearlyValues } from 'prosumer-shared/models';

import { SelectionModel } from '@angular/cdk/collections';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
} from '@angular/core';
import { NgControl } from '@angular/forms';

import { EditableCellChangeI } from '../../models';

const SELECTOR = 'prosumer-sparkline-table';

export const DEFAULT_TABLE_MESSAGES = {
  loading: 'Generic.messages.loadingData',
  noRecords: 'Generic.messages.noRecordsFound',
  noResults: 'Generic.messages.noResultsFound',
  error: 'Generic.messages.errorLoadingData',
};

@Component({
  selector: SELECTOR,
  templateUrl: './sparkline-table.component.html',
  styleUrls: ['./sparkline-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [rowAnimation],
  providers: [ManagedDataService],
})
export class SparklineTableComponent<T>
  extends ManagedTableComponent<T>
  implements OnInit
{
  @Input() idField = 'id';
  @Input() saving: boolean;
  @Input() maxHeight: string;
  @Input() hideAdd: boolean;
  @Input() hideEdit: boolean;
  @Input() hideDuplicate = true;
  @Input() hideDelete: boolean;
  @Input() hideView = true;
  @Input() noWrap: boolean;
  @Input() noDash: boolean;
  @Input() messages = DEFAULT_TABLE_MESSAGES;
  @Input() cellTooltip: boolean;
  @Input() listItemLoading: Record<string, boolean> = {};
  @Input() duplicable = false;
  @Input() disabledAddBtnMessage: string | undefined;
  @Input() hideHeader = false;
  @Input() hideMessages = false;
  @Input() isViewOnly = false;

  selection = new SelectionModel<string>(false);
  @Input() set selected(value: string) {
    this.selection.select(value);
  }

  _disableNewButton = false;
  @Input() set disableNewButton(value) {
    this._disableNewButton = value;
  }
  get disableNewButton() {
    return this._disableNewButton;
  }

  @Output() selectionChanged = new EventEmitter<string>();
  @Output() duplicate = new EventEmitter();
  @Output() editableCellChanged = new EventEmitter<EditableCellChangeI>();

  selector = SELECTOR;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    public changeDetector: ChangeDetectorRef,
    @Self() public managedData: ManagedDataService<T>,
  ) {
    super(ngControl, changeDetector, managedData);
  }

  ngOnInit() {
    super.ngOnInit();
    this.selection.changed
      .pipe(this.takeUntil())
      .subscribe(({ source: { selected } }) =>
        this.selectionChanged.emit(selected[0]),
      );
  }

  /**
   * Override
   */
  defineColumns() {
    return this.columnsDef || {};
  }

  /**
   * Override
   */
  onAdd() {
    this.add.emit();
  }

  /**
   * Override
   */
  onEdit(data: T) {
    this.edit.emit(data);
  }

  /**
   * Override
   */
  onDuplicate(data: T) {
    if (this.duplicable) {
      this.onNewDuplicate(data);
    } else {
      this.add.emit(data);
    }
  }

  private onNewDuplicate(data: T): void {
    this.duplicate.emit(data);
  }

  isLoadingStatus(data: T): boolean {
    return [this.isDuplicating(data)].some(Boolean);
  }

  isDuplicating(data: T): boolean {
    return this.getDataStatus(data).toLowerCase() === 'duplicating';
  }

  private getDataStatus(data: T): string {
    return data['status'] || '';
  }

  /**
   * Override
   */
  onDelete(data: T) {
    this.delete.emit(data);
  }

  /**
   * Checks if yearly values has different values
   *
   * @param value - the yearly values
   */
  hasMultipleValues(value: YearlyValues) {
    return hasMultipleValues(value);
  }

  /**
   * Get value of the keys from references.
   *
   * @param keys is a list of string. e.g ['abc','def'].
   * @param referenceKey a key from references.
   */
  getValueFromKeyList(keys: Array<string>, referenceKey?: string): string {
    const value = [];
    if (!!keys && keys instanceof Array) {
      if (!!referenceKey) {
        keys.forEach((key) =>
          value.push(this.references[referenceKey][key] || key),
        );
      } else {
        keys.forEach((key) => value.push(this.references[key] || key));
      }
    }
    return arrayToString(value.sort(), ',');
  }

  getTooltipFromReferences(cellValue: string, referenceKey: string): string {
    return this.references[referenceKey][cellValue];
  }

  onEditableCellChange(n: string, o: string, key: string) {
    this.editableCellChanged.emit({
      key,
      oldValue: o,
      newValue: n,
    });
  }
}
