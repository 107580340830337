export interface UserInfo {
  id: string;
  username: string;
  email?: string;
  emailVerified?: boolean;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  phoneNumber?: string;
  phoneNumberVerified?: boolean;
  groups?: string[];
}
