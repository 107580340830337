import { OperatingCost } from 'prosumer-scenario/models';
import { HasProfileGroup } from '../profile';
import {
  DetailEntity,
  DetailResponse,
  HasDataType,
  ScenarioDetailState,
} from '../scenario-detail';

export type GetOpCostsRsp = DetailResponse<OperationalCostBE>;
export type OperationalCostInfo = HasId &
  HasEquipmentId &
  OperatingCost &
  DetailEntity;
export type OperationalCostBE = HasEquipmentId &
  HasOperationalCostId &
  OperatingCost &
  HasProfileGroup &
  HasDataType;
export type CreateOpCostBody = Omit<
  OperationalCostInfo,
  'id' | 'equipmentId' | 'loading' | 'energyVectorName'
>;

export interface OpCostForm {
  readonly profiles: unknown[];
  readonly parameterType: string;
  readonly outputEnergyVector: string;
  readonly inputEnergyVector: string;
}

interface HasId {
  readonly id: string;
}

interface HasEquipmentId {
  readonly equipmentId: string;
}

interface HasOperationalCostId {
  readonly operationalCostId: string;
}

export type OperationalCostState = ScenarioDetailState<OperationalCostInfo>;
