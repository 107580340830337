import { DialogData } from 'prosumer-app/libs/eyes-shared';
import { CustomOptions } from '../../../models/optimization-cockpit.model';
export interface CustomOptionsFormDialogData extends CustomOptions, DialogData {
  mode: 'add' | 'edit';
  isViewOnly?: boolean;
  scenarioIdentity?: {
    projectId: string;
    caseId: string;
    scenarioId: string;
  };
  id?: string;
}
