export type ScenarioBinData = Readonly<{
  id?: string;
  location?: string;
  data?: Array<string>;
  deleteSuccess?: boolean;
}>;

export type ScenarioBinStoreState = Readonly<{
  loading?: boolean;
  listLoading?: boolean;
  data?: ScenarioBinData;
  listData?: Array<ScenarioBinData>;
  cachedData?: Map<string, ScenarioBinData>;
  error?: any;
  listError?: any;
}>;
