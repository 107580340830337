export type TooltipPosition =
  | 'right'
  | 'left'
  | 'center'
  | 'top-right'
  | 'top-left';

export type TooltipPositionOrigins = {
  originX: 'center' | 'end' | 'start';
  originY: 'center' | 'bottom' | 'top';
  overlayX: 'center' | 'end' | 'start';
  overlayY: 'center' | 'bottom' | 'top';
};
