<prosumer-dialog-div
  [title]="
    'Scenario.messages.cascade.cascadeTitle'
      | translate
        : {
            type: translatedType | titlecase,
            action: translatedAction | titlecase
          }
  "
  [okLabel]="'continue'"
  (ok)="onOk()"
  (cancel)="onCancel()"
>
  <span class="cascade-confirmation-msg">
    {{
      'Scenario.messages.cascade.cascadeConfirmation'
        | translate: { type: translatedType, action: translatedAction }
    }}
  </span>
  <div class="affected-entities-container">
    @if (tableData()?.delete?.length) {
      <div class="affected-entities-container-deleted">
        <h3 class="affected-entities-title">
          {{ 'Scenario.messages.cascade.deleteTitle' | translate }}
        </h3>
        <prosumer-sparkline-table
          class="affected-entities-table"
          [columnsDef]="columnDef"
          [data]="tableData()['delete']"
          [hideAdd]="true"
          [hideEdit]="true"
          [hideDelete]="true"
          [hideView]="true"
          [searchInput]="false"
          [hideMessages]="true"
          [hideHeader]="false"
        >
        </prosumer-sparkline-table>
      </div>
    }
    @if (tableData()?.update?.length) {
      <div class="affected-entities-container-updated">
        <h3 class="affected-entities-title">
          {{ 'Scenario.messages.cascade.updateTitle' | translate }}
        </h3>
        <prosumer-sparkline-table
          class="affected-entities-table"
          [columnsDef]="columnDef"
          [data]="tableData()['update']"
          [hideAdd]="true"
          [hideEdit]="true"
          [hideDelete]="true"
          [hideView]="true"
          [searchInput]="false"
          [hideMessages]="true"
          [hideHeader]="false"
        >
        </prosumer-sparkline-table>
      </div>
    }
  </div>
</prosumer-dialog-div>
