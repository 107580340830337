import { Utils } from 'prosumer-app/core';
import { Affected } from './scenario-variation-cascader.base';

type AffectedGroups = Record<string, Affected[]>;

export class CascadeMessageFormatter {
  static format(affected: Affected[]): string {
    return this.shouldFormat(affected) ? this.doFormat(affected) : '';
  }

  private static shouldFormat(affected: Affected[]): boolean {
    return affected.length > 0;
  }

  private static doFormat(affected: Affected[]): string {
    return `<div class="list-wrapper"><b>Affected Data</b><br>${this.buildAffectedMessage(
      affected,
    )}</div>`;
  }

  private static buildAffectedMessage(affected: Affected[]): string {
    return Object.entries(this.groupAffected(affected)).reduce<string>(
      (acc, [category, affected]) =>
        acc + this.groupToMessage(category, affected),
      '',
    );
  }

  private static groupAffected(affected: Affected[]): AffectedGroups {
    return affected.reduce<AffectedGroups>((acc, curr) => {
      acc[curr.category] = [
        ...Utils.resolveToEmptyArray(acc[curr.category]),
        curr,
      ];
      return acc;
    }, {});
  }

  private static groupToMessage(
    category: string,
    affected: Affected[],
  ): string {
    return `${category}:${this.manyAffectedToListInString(affected)}`;
  }

  private static manyAffectedToListInString(affected: Affected[]): string {
    return `<ul>${this.manyAffectedToMessages(affected).join('')}</ul>`;
  }

  private static manyAffectedToMessages(affected: Affected[]): string[] {
    return affected.map((affected) => this.singleAffectedToMessage(affected));
  }

  private static singleAffectedToMessage(affected: Affected): string {
    return `<li>${affected.name}</li>`;
  }
}
