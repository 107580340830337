<ngx-charts-bar-vertical-stacked
  [view]="view"
  [results]="filteredData$ | async"
  [scheme]="chartScheme$ | async"
  [xAxis]="true"
  [xAxisLabel]="xAxisLabel"
  [showXAxisLabel]="true"
  [yAxis]="true"
  [yAxisLabel]="yAxisLabel"
  [showYAxisLabel]="true"
  [legend]="true"
  [legendPosition]="legendPosition"
  (activate)="onActivate($event)"
  (select)="onSelect($event)"
>
  <ng-template #tooltipTemplate let-model="model">
    <prosumer-chart-tooltip
      [currentTooltipData]="model"
      [tooltipDataList]="tooltipData"
      [unit]="yAxisLabel"
      [titleFormat]="tooltipTitleFormat"
    >
    </prosumer-chart-tooltip>
  </ng-template>
</ngx-charts-bar-vertical-stacked>
