import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { AuthService } from '../../services';

@Component({
  selector: 'oculus-login-callback',
  template: '<h3>Please wait... Redirecting you to login page.</h3>',
  styles: ['p { margin: 1rem }'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginCallbackComponent implements OnInit {
  constructor(private readonly authService: AuthService) {}

  ngOnInit(): void {
    this.authService.login();
  }
}
