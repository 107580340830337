export type ResultType = 'warning' | 'error';

export type CoherenceResult = Readonly<{
  type?: ResultType;
  message?: string;
  stepId?: string;
  groupId?: string;
  fieldId?: string;
  scenarioTab?: string;
}>;

export type CoherenceStepValidation = {
  stepLabel: string;
  validations: CoherenceResult[];
};
