import { EquipmentCircuitInfo } from 'prosumer-app/+scenario/models';
import { DetailEntity } from '../scenario-detail';
import { YearValues } from '../scenario-generic';

export type ShortCircuitEquipment = EquipmentCircuitInfo & DetailEntity;
export type ShortCircuitEquipmentOmit = Omit<
  ShortCircuitEquipment,
  'shortCircuitFactor' | 'shortCircuitThreshold'
>;
export type ShortCircuitEquipmentOutgoing = ShortCircuitEquipmentOmit &
  Readonly<{ factor: YearValues; threshold: YearValues }>;
export type ShortCircuitEquipmentIncoming = ShortCircuitEquipmentOmit &
  Readonly<{ factor: string; threshold: string }>;
