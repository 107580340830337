import { TDBGranularity } from './generic.model';

export interface EmissionFactorsListResponseModel {
  data: EmissionFactorsListDataResponseModel[];
  granularity: TDBGranularity;
  startYear: number;
  endYear: number;
}

export interface EmissionFactorsListDataResponseModel {
  emissionCategory: 'string';
  item: 'string';
  granularity: 'string';
  geography: 'string';
  scenario: 'string';
  scope: 'string';
  unit: 'string';
  values: Record<string, string | number>;
}
