import { ENGIE_DEFAULT_SCHEME } from 'prosumer-app/app.references';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PerceptionMap } from '@prosumer/results/components/case-results-perception';
import { VisualizerData } from '@prosumer/results/components/results-visualizer';
import { ColorHelper, Color, ScaleType } from '@swimlane/ngx-charts';

import { FilterableLegend } from '../../base';
import { NgxChartUtils } from '../../utils';

@Component({
  selector: 'prosumer-stacked-bar-chart',
  templateUrl: './stacked-bar-chart.component.html',
  styleUrls: ['./stacked-bar-chart.component.scss'],
})
export class StackedBarChartComponent extends FilterableLegend {
  private dataSubject = new BehaviorSubject<unknown[]>([]);
  private colorMapSubject = new BehaviorSubject<PerceptionMap>({});
  private chartSchemeSubject = new BehaviorSubject<unknown>(
    ENGIE_DEFAULT_SCHEME,
  );

  tooltipData: unknown[];
  chartScheme$: Observable<unknown>;

  @Input() set data(data: unknown[]) {
    this.dataSubject.next(data || []);
  }
  get data() {
    return this.dataSubject.value;
  }
  @Input() xAxisLabel: string;
  @Input() yAxisLabel: string;
  @Input() view: [number, number];
  @Input() legendPosition: 'right' | 'below' = 'right';
  @Input() set colorMap(perMap: PerceptionMap) {
    this.colorMapSubject.next(perMap);
  }
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() select = new EventEmitter<unknown>();

  readonly tooltipTitleFormat = (tooltipData: unknown) =>
    tooltipData ? tooltipData['name'] : tooltipData;
  constructor() {
    super();

    this.chartScheme$ = this.chartSchemeSubject.asObservable();
    this.subscribeToColorMapAndDataSubjectsForColorSchemeSynchronization();
  }

  onSelect(data: unknown): void {
    this.select.emit(data);
    this.onLegendSelect(data);
  }

  onActivate(seriesModel: unknown) {
    const filtered = this.data.filter(
      (yearBox) =>
        this.getNameFromResult(yearBox) === seriesModel['value']?.series,
    );
    const filteredZero = filtered[0];
    const filteredZeroSeries = this.getSeriesFromResult(filteredZero);
    if (filtered && filteredZero && filteredZeroSeries) {
      const filteredZeroName = this.getNameFromResult(filteredZero);
      this.tooltipData = filteredZeroSeries.map((item) => ({
        series: item.name,
        value: item.value,
        name: filteredZeroName,
        current: seriesModel['value']?.name === filteredZeroName,
        color: new ColorHelper(
          this.chartSchemeSubject.value as Color,
          ScaleType.Ordinal,
          filteredZeroSeries.map((d) => d.name),
        ).getColor(item.name),
      }));
    }
  }

  getChartDataStream(): Observable<unknown[]> {
    return this.dataSubject.asObservable();
  }
  getLegendNames(data: unknown[]): string[] {
    return this.getSeriesFromResult(data[0]).map(
      (single: unknown) => single['name'],
    );
  }
  mutateSingleChartDataBasedOnLegend<T>(single: T): T {
    return {
      ...single,
      series: this.getSeriesFromResult(single).map((siri) =>
        this.mutateMapSiriValueBasedOnShowing(
          siri,
          this.shouldShowData(siri.name),
        ),
      ),
    };
  }

  private subscribeToColorMapAndDataSubjectsForColorSchemeSynchronization(): void {
    combineLatest([this.dataSubject, this.colorMapSubject])
      .pipe(
        map(([data, colorMap]) =>
          NgxChartUtils.generateBarColorScheme(
            data as VisualizerData[],
            colorMap,
          ),
        ),
      )
      .subscribe((scheme) => this.chartSchemeSubject.next(scheme));
  }

  private mutateMapSiriValueBasedOnShowing(
    siri: { name: string; value: string },
    showing: boolean,
  ): unknown {
    return {
      name: this.mutateNameBasedOnShowOrHide(siri.name),
      value: showing ? siri.value : 0,
    };
  }

  private getNameFromResult(result: unknown): string {
    return (result || {})['name'] || '';
  }

  private getSeriesFromResult(
    result: unknown,
  ): { name: string; value: string }[] {
    return (result || {})['series'] || [];
  }
}
