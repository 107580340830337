<form
  [formGroup]="form"
  (ngSubmit)="onClickNext()"
  prosumerNodesGetter
  prosumerVectorsGetter
  *ngrxLet="refs$ as refs"
>
  <!-- Energy Grid Connections -->
  <mat-expansion-panel [expanded]="expanded">
    <mat-expansion-panel-header
      [collapsedHeight]="collapsedHeight"
      [expandedHeight]="expandedHeight"
    >
      <mat-panel-title data-testid="energy-grid-connections-panel-title">
        {{ 'Scenario.labels.wizard.commodities.headers.grids' | translate }}
        <prosumer-tooltip-anchor
          [message]="
            'wizard_commodities.wizard_commodities_energy_grid_connections'
          "
        >
        </prosumer-tooltip-anchor>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <prosumer-sparkline-table
      prosumerEgcLister
      (egcsChange)="egcs = $event"
      [columnsDef]="gridConnectionsColumnDef"
      [references]="refs"
      [data]="egcs"
      [searchInput]="true"
      [searchPredicate]="gridSearchPredicate"
      (add)="onAddGrid()"
      (edit)="onEditGrid($event)"
      (delete)="onDeleteGrid($event)"
      (duplicate)="onDuplicateGrid($event)"
      [duplicable]="true"
      [hideAdd]="isViewOnly || mode === 'read'"
      [hideEdit]="isViewOnly || mode === 'read'"
      [hideDuplicate]="isViewOnly || mode === 'read'"
      [hideDelete]="isViewOnly || mode === 'read'"
      [hideView]="!isViewOnly && mode !== 'read'"
      data-testid="egcTable"
    ></prosumer-sparkline-table>
  </mat-expansion-panel>

  <!-- Energy Grid Connections Emissions-->
  <mat-expansion-panel [expanded]="expanded">
    <mat-expansion-panel-header
      [collapsedHeight]="collapsedHeight"
      [expandedHeight]="expandedHeight"
    >
      <mat-panel-title
        data-testid="energy-grid-connections-emissions-panel-title"
      >
        {{
          'Scenario.labels.wizard.commodities.headers.connectionsEmissions'
            | translate
        }}
      </mat-panel-title>
    </mat-expansion-panel-header>

    <prosumer-sparkline-table
      [columnsDef]="gridConnectionsEmissionsColumnDef"
      [references]="refs"
      [data]="egcEmissions$ | ngrxPush"
      [searchInput]="true"
      [searchPredicate]="connectionsEmissionsSearchPredicate"
      (add)="onAddConnectionsEmission()"
      (edit)="onEditConnectionsEmission($event)"
      (delete)="onDeleteEmission($event)"
      (duplicate)="onDuplicateEmission($event)"
      [duplicable]="true"
      [hideAdd]="isViewOnly || mode === 'read'"
      [hideEdit]="isViewOnly || mode === 'read'"
      [hideDuplicate]="isViewOnly || mode === 'read'"
      [hideDelete]="isViewOnly || mode === 'read'"
      [hideView]="!isViewOnly && mode !== 'read'"
      data-testid="emissionsTable"
    ></prosumer-sparkline-table>
  </mat-expansion-panel>

  <!-- Market Limits -->
  <mat-expansion-panel [expanded]="expanded">
    <mat-expansion-panel-header
      [collapsedHeight]="collapsedHeight"
      [expandedHeight]="expandedHeight"
    >
      <mat-panel-title [attr.data-testid]="'market-limits-panel-title'">
        {{
          'Scenario.labels.wizard.commodities.headers.marketLimits' | translate
        }}
        <prosumer-tooltip-anchor
          [attr.data-testid]="'market-limits-panel-title-help'"
          [message]="'wizard_commodities.wizard_market_limits_section'"
        ></prosumer-tooltip-anchor>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <prosumer-sparkline-table
      [columnsDef]="gridLimitColumnDef"
      [references]="refs"
      [data]="limits$ | ngrxPush"
      [searchInput]="true"
      [searchPredicate]="limitSearchPredicate"
      (add)="onAddEnergyGridLimit($event)"
      (edit)="onEditEnergyGridLimit($event)"
      (delete)="onDeleteLimit($event)"
      (duplicate)="onDuplicateEnergyGridLimit($event)"
      [duplicable]="true"
      [hideAdd]="isViewOnly || mode === 'read'"
      [hideEdit]="isViewOnly || mode === 'read'"
      [hideDuplicate]="isViewOnly || mode === 'read'"
      [hideDelete]="isViewOnly || mode === 'read'"
      [hideView]="!isViewOnly && mode !== 'read'"
      data-testid="limitsTable"
    ></prosumer-sparkline-table>
  </mat-expansion-panel>

  <!-- Fuels -->
  <mat-expansion-panel [expanded]="expanded">
    <mat-expansion-panel-header
      [collapsedHeight]="collapsedHeight"
      [expandedHeight]="expandedHeight"
    >
      <mat-panel-title data-testid="fuel-panel-title">
        {{ 'Scenario.labels.wizard.commodities.headers.fuels' | translate }}
        <prosumer-tooltip-anchor
          [message]="'wizard_commodities.wizard_commodities_fuels'"
        >
        </prosumer-tooltip-anchor>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <prosumer-sparkline-table
      prosumerFuelsLister
      (fuelsChange)="fuels = $event"
      [columnsDef]="fuelColumnDef"
      [references]="refs"
      [data]="fuels"
      [searchInput]="true"
      [searchPredicate]="fuelSearchPredicate"
      (add)="onAddFuel()"
      (edit)="onEditFuel($event)"
      (delete)="onDeleteFuel($event)"
      [hideAdd]="isViewOnly || mode === 'read'"
      [hideEdit]="isViewOnly || mode === 'read'"
      [hideDuplicate]="true"
      [hideDelete]="isViewOnly || mode === 'read'"
      [hideView]="!isViewOnly && mode !== 'read'"
      data-testid="fuelsTable"
    ></prosumer-sparkline-table>
  </mat-expansion-panel>

  <!-- Emissions -->
  <mat-expansion-panel [expanded]="expanded">
    <mat-expansion-panel-header
      [collapsedHeight]="collapsedHeight"
      [expandedHeight]="expandedHeight"
    >
      <mat-panel-title data-testid="emissions-panel-title">
        {{ 'Scenario.labels.wizard.commodities.headers.emissions' | translate }}
        <prosumer-tooltip-anchor
          [message]="'wizard_topology.wizard_commodities_fuels'"
        >
        </prosumer-tooltip-anchor>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <prosumer-commodities-emissions></prosumer-commodities-emissions>
  </mat-expansion-panel>

  <prosumer-stepper-nav
    [disabled]="disabled"
    [previousDisabled]="previousDisabled"
    [previousLabel]="previousLabel"
    [previousColor]="previousColor"
    [nextDisabled]="nextDisabled"
    [nextLabel]="nextLabel"
    [nextColor]="nextColor"
    [reverseButtons]="reverseButtons"
    (previous)="onClickPrevious()"
  ></prosumer-stepper-nav>
</form>
