import { Netting } from 'prosumer-app/+scenario';
import { ProfileType } from 'prosumer-app/+scenario/types';
import { DialogData, FormFieldOption } from 'prosumer-app/libs/eyes-shared';
import { Observable } from 'rxjs';

export interface EquipmentFormDialogData<T> extends DialogData {
  mode: 'add' | 'edit' | 'duplicate';
  isViewOnly?: boolean;
  profileType?: ProfileType;
  profileOptions?: Array<FormFieldOption<ProfileType>>;
  scenarioVariationOptions?: Array<FormFieldOption<any>>;
  outputEnergyVectorOptions?: Array<FormFieldOption<any>>;
  nodeOptions?: Array<FormFieldOption<string>>;
  equipment$?: Observable<Array<T>>;
  equipment?: T;
  isMultiNode?: boolean;
  hasMobilityFeature?: boolean;
  equipmentTypeOptions?: Array<FormFieldOption<any>>;
  hasTdbAcces?: boolean;

  scenarioIdentity?: {
    projectId: string;
    caseId: string;
    scenarioId: string;
  };
  startYear?: number;
  endYear?: number;

  currentNetting?: Array<Netting>;
}
