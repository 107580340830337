import { EntityState } from '@ngrx/entity';
import { ReadEntity } from './read-entity.model';
import { CreateEntity } from './create-entity.model';

export interface BaseEntityState<T>
  extends EntityState<T>,
    ReadEntity,
    CreateEntity {
  selectedId?: string;
}
