import {
  EnergyGridConnectionsEmissions,
  ProfileGroupBE,
} from 'prosumer-app/+scenario/models';
import { HasTDBProfileFilters } from 'prosumer-app/shared/modules/tdb/models';

import {
  DetailEntity,
  DuplicateDetailData,
  HasDataType,
  HasScenarioUuid,
  HasScenarioVariation,
  ScenarioDetailState,
} from '../scenario-detail';

export type EGCEmission = EnergyGridConnectionsEmissions &
  DetailEntity &
  Partial<HasConnectedEgcId> &
  Partial<HasScenarioVariation> &
  Partial<HasScenarioUuid>;
export type EGCEmissionForm = EGCEmission;
export type EGCEmissionState = ScenarioDetailState<EGCEmission>;

export type EGCEmissionBE = Partial<HasScenarioUuid> &
  Partial<HasTDBProfileFilters> &
  Partial<HasScenarioVariation> &
  Partial<HasDataType> &
  Partial<HasConnectedEgcId> & {
    id?: string;
    name?: string;
    nodeId?: string;
    scope?: string;
    emissionsRate?: ProfileGroupBE;
  };

interface HasConnectedEgcId {
  connectedEgcId: string;
}

export type EGCEmissionIdentifier = Pick<
  EGCEmission,
  'marketName' | 'node' | 'scope'
>;
export type EmissionDuplicateParams = Pick<
  EGCEmissionBE,
  'connectedEgcId' | 'nodeId' | 'scope'
>;
export type EmissionDuplicateDialogData = DuplicateDetailData<EGCEmission>;
