export type PolicyDialogData = Readonly<{
  title?: string;
  message?: string;
  confirm?: string;
  close?: string;
  width?: number;
  disableClose?: boolean;
  nonCancellable?: boolean;
  panelClass?: string | string[];
}>;
