import { ComponentType } from '@angular/cdk/portal';

export type DialogData = Readonly<{
  title?: string;
  message?: string;
  confirm?: string;
  close?: string;
  width?: number | string;
  disableClose?: boolean;
  nonCancellable?: boolean;
  panelClass?: string | string[];
}>;
