import {
  EnergyGridConnectionsEmissions,
  EnergyGridLimit,
} from 'prosumer-app/+scenario/models';
import { DialogData, FormFieldOption } from 'prosumer-app/libs/eyes-shared';
import { EnergyGridConnection } from 'prosumer-scenario';
import { Observable } from 'rxjs';

export type EnergyGridConnectionsDialogMode = 'add' | 'edit' | 'duplicate';

export interface EnergyGridConnectionsDialogData
  extends EnergyGridConnection,
    DialogData {
  mode: EnergyGridConnectionsDialogMode;
  deliveryOptions: Array<FormFieldOption<any>>;
  nodeOptions: Array<FormFieldOption<any>>;
  typeOptions: Array<FormFieldOption<any>>;
  scenarioVariationOptions?: Array<FormFieldOption<any>>;
  energyTariffOptions: Array<FormFieldOption<any>>;
  existingEnergyGridConnections$: Observable<Array<EnergyGridConnection>>;
  energyGridConnectionData?: EnergyGridConnection;
  isMultiNode?: boolean;
  startYear?: number;
  endYear?: number;
  connectionsEmissions: Array<EnergyGridConnectionsEmissions>;
  energyGridLimits?: Array<EnergyGridLimit>;
  isViewOnly?: boolean;
  scenarioIdentity?: {
    projectId: string;
    caseId: string;
    scenarioId: string;
  };
}
