import { EnergyVectorCircuitInfo } from 'prosumer-app/+scenario/models';
import { DetailEntity } from '../scenario-detail';
import { YearValues } from '../scenario-generic';

export type PowerNeeded = EnergyVectorCircuitInfo & DetailEntity;
export type PowerNeededOutgoing = Pick<PowerNeeded, 'energyVectorId'> &
  Readonly<{
    powerNeeded: YearValues;
  }>;
export type PowerNeededIncoming = Omit<PowerNeeded, 'neededPower'> &
  Readonly<{
    powerNeeded: string;
  }>;
