import { Equipment, EquipmentCircuitInfo } from 'prosumer-app/+scenario/models';
import { DialogData } from 'prosumer-app/libs/eyes-shared';
import { HasId } from 'prosumer-app/stores';

export interface SCEquipmentFormData extends DialogData {
  mode: 'add' | 'edit' | 'view';
  equipment?: EquipmentCircuitInfo & Partial<HasId>;
  equipments: Array<EquipmentCircuitInfo>;
  equipmentOptions: Array<Equipment>;
  startYear?: number;
  endYear?: number;
}
