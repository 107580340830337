export type WebSocketConfig = Readonly<{
  url: string;
  /* Keep Alive */
  keepAlive?: boolean;
  keepAliveIntervalMs?: number;
  /* Retry */
  retryOnError?: boolean;
  retryOnClose?: boolean;
  maxRetryAttempts?: number;
  retryScalingDuration?: number;
}>;
