/* eslint-disable no-useless-escape */
import {
  CommoditiesForm,
  EquipmentForm,
  FrequencyControlForm,
  GeneralForm,
  LoadsForm,
  MobilityForm,
  NettingForm,
  OptimizationCockpitForm,
  RegulationsForm,
  ScenarioEntity,
  TimeForm,
  TopologyForm,
} from '../models';

export const VALID_SCENARIO_NAME_PATTERN = /[^a-z0-9\s\.\-_()+]/gi;

export interface InputFile {
  readonly name?: string;
  readonly size?: number;
  readonly status?: string;
  readonly url?: string;
  readonly created?: string;
  readonly updated?: string;
}

export interface LogFile {
  readonly name?: string;
  readonly key?: string;
}

export interface RunMap {
  readonly status: string;
  readonly launchDate: string;
  readonly duration: string;
  readonly inputFile: InputFile;
  readonly systemFile?: OutputFile;
  readonly logFile?: LogFile;
  readonly messageFile?: LogFile;
  readonly isOutputSplit?: boolean;
  readonly outputJsonVersion?: string;
  readonly outputValues?: OutputValues;
}

export interface OutputValues {
  readonly globalTotalCost: number;
  readonly globalFinalCO2Emissions: number;
}

export type ScenarioCase = Scenario | ScenarioVariationMap;

export interface ScenarioVariationMap {
  readonly variationId?: string;
  readonly name?: string;
  readonly run?: RunMap;
}

export interface Scenario extends ScenarioEntity {
  // Identities
  readonly projectId?: string;
  readonly caseId?: string;

  // Fields
  readonly owner?: string;
  readonly description?: string;
  readonly createdFrom?: string;
  readonly createdDate?: string;
  readonly updatedDate?: string;
  readonly scenarioType?: string;
  readonly copyStatus?: string;
  readonly colorClass?: string;
  readonly run?: RunMap;
  readonly inputFileGenerated?: boolean;

  // For Scenario Variations
  readonly hasScenarioVariations?: boolean;
  readonly scenarioVariations?: Array<ScenarioVariationMap>;
  readonly mergeResults?: MergeResults;

  // Wizard
  readonly general?: GeneralForm;
  readonly time?: TimeForm;
  readonly topology?: TopologyForm;
  readonly loads?: LoadsForm;
  readonly equipments?: EquipmentForm;
  readonly commodities?: CommoditiesForm;
  readonly frequencyControl?: FrequencyControlForm;
  readonly netting?: NettingForm;
  readonly regulations?: RegulationsForm;
  readonly mobility?: MobilityForm;
  readonly optimizationCockpit?: OptimizationCockpitForm;

  // For Sorting
  readonly name?: string;
  readonly status?: string;
  readonly launchDate?: string;
  readonly duration?: string;
  readonly globalTotalCost?: number;
  readonly globalFinalCO2Emissions?: number;

  // AdHoc (Latest Scenario)
  readonly projectName?: string;
  readonly caseName?: string;
  readonly outputFile?: any;
  readonly logFile?: any;
  readonly simulationId?: string;

  // For Conflict handling
  readonly updatedBy?: string;
  readonly updatedById?: string;
}

/* TODO Remove this since this should be compatible with the new models */
export type OutputFile = Readonly<{
  name?: string;
  url?: string;
  size?: number;
  createdAt?: string;
  updatedAt?: string;
  status?: string;
}>;

export type LatestScenario = Readonly<{
  name?: string;
  id?: string;
  projectName?: string;
  projectId?: string;
  caseName?: string;
  caseId?: string;
  launchDate?: string;
  duration?: string;
  run?: RunMap;
  outputFile?: OutputFile;
  logFile?: string;
  simulationId?: string;
  scenarioType?: string;
  status?: string;
}>;

export interface MergeResults {
  readonly outputFile?: LogFile;
  readonly logFile?: LogFile;
  readonly messageFile?: LogFile;
}

export interface ScenarioDetails {
  readonly projectId: string;
  readonly caseId: string;
  readonly scenarioId: string;
  readonly variationId: string;
}
