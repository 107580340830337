import { OculusUtils } from '@oculus/utils/helpers';
import { EngieColor } from '@oculus/utils/models';

export class ColorService {
  static getColor(index?: number): EngieColor {
    const colorIndex = index ?? this.getRandomizedIndex();
    return this.engieColors[colorIndex];
  }

  private static getRandomizedIndex(): number {
    const totalColors = this.engieColors.length;
    return OculusUtils.generateRandomNumber(totalColors);
  }

  private static get engieColors(): EngieColor[] {
    return Object.values(EngieColor);
  }
}
