import { EnergyVector, NetworkReserve } from 'prosumer-app/+scenario/models';
import { DialogData } from 'prosumer-app/libs/eyes-shared';
import { HasId } from 'prosumer-app/stores/scenario-detail';

export interface ReserveNetworkFormDialogData extends DialogData {
  mode: 'add' | 'edit' | 'view';
  energyVectorOptions: Array<EnergyVector>;
  currentReserveNetwork: NetworkReserve & Partial<HasId>;
  currentReserveNetworkList: Array<NetworkReserve>;
}
