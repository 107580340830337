import { DialogData, FormFieldOption } from 'prosumer-app/libs/eyes-shared';
import {
  EnergyGridConnection,
  EnergyGridConnectionsEmissions,
} from 'prosumer-scenario';
import { Observable } from 'rxjs';

export type EnergyGridConnectionsEmissionsDialogMode =
  | 'add'
  | 'edit'
  | 'duplicate';

export interface EnergyGridConnectionsEmissionsDialogData
  extends EnergyGridConnectionsEmissions,
    DialogData {
  mode: EnergyGridConnectionsEmissionsDialogMode;
  marketOptions: Array<FormFieldOption<any>>;
  nodeOptions: Array<FormFieldOption<any>>;
  scopeOptions: Array<FormFieldOption<any>>;
  scenarioVariationOptions?: Array<FormFieldOption<any>>;
  existingEnergyGridConnections$: Observable<Array<EnergyGridConnection>>;
  existingEnergyGridConnectionsEmissions$: Observable<
    Array<EnergyGridConnectionsEmissions>
  >;
  energyGridConnectionsEmissionsData?: EnergyGridConnectionsEmissions;
  isMultiNode?: boolean;
  startYear?: number;
  endYear?: number;
  scenarioIdentity?: {
    projectId: string;
    caseId: string;
    scenarioId: string;
  };
  isViewOnly?: boolean;
}
