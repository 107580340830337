import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';

import { SharedLandingPageComponent } from './shared-landing-page.component';

@NgModule({
  declarations: [SharedLandingPageComponent],
  imports: [CommonModule, MatButtonModule, MatFormFieldModule, TranslateModule],
  exports: [SharedLandingPageComponent],
})
export class SharedLandingPageModule {}
