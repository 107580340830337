import { DialogData, FormFieldOption } from 'prosumer-app/libs/eyes-shared';
import { Observable } from 'rxjs';

import { Load, Netting, Profile } from '../../../models';

export type LoadFormDialogMode = 'add' | 'edit' | 'duplicate';
export type LoadFormDialogType = 'library' | 'custom';

export interface LoadFormDialogData extends DialogData {
  mode: LoadFormDialogMode;
  isViewOnly: boolean;
  loadTypeOptions: Array<FormFieldOption<LoadFormDialogType>>;
  energyVectorOptions: Array<FormFieldOption<any>>;
  nodeOptions: Array<FormFieldOption<any>>;
  endUseLoads$: Observable<Array<Load>>;
  endUseLoad?: Load;
  scenarioIdentity?: { projectId: string; caseId: string; scenarioId: string };
  isMultiNode?: boolean;
  startYear?: number;
  endYear?: number;
  persistedProfiles?: Array<Profile>;
  currentNetting?: Array<Netting>;
  scenarioVariationOptions?: Array<FormFieldOption<any>>;
  scenarioVariation?: string;
}
