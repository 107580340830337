import { DialogData, FormFieldOption } from 'prosumer-app/libs/eyes-shared';
import { EnergyVector } from 'prosumer-scenario';
import { Observable } from 'rxjs';

export interface EnergyVectorFormDialogData extends DialogData {
  mode: 'add' | 'edit';
  energyVectorTypeOptions: Array<FormFieldOption<string>>;
  energyVectors$: Observable<Array<EnergyVector>>;
  energyVector?: EnergyVector;
}
