import { ApiConfig } from './api-config.model';
import { CognitoConfig } from './cognito-config.model';
import { HttpConfig } from './http-config.model';
import { PubSubConfig } from './pubsub-config.model';
import { Version } from './version-config.model';
import { WebSocketConfig } from './websocket-config.model';

export interface EnvConfig {
  name: string;
  production: boolean;
  http?: HttpConfig;
  cognito?: CognitoConfig;
  api?: ApiConfig;
  pubSub?: PubSubConfig;
  version?: Version;
  websocket?: WebSocketConfig;
  sentryDsn?: string;
  sentryRelease?: string;
  homeUrl?: string;
  faqUrl?: string;
  trainingUrl?: string;
  googleApiKey?: string;
  captchaSiteKey?: string;
}
