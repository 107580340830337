import { DialogData } from 'prosumer-app/libs/eyes-shared';
import { YearlyValues } from 'prosumer-app/shared';
import { NodeInfo } from 'prosumer-app/stores/node';

export interface Node {
  readonly name?: string;
  readonly value?: string;
  readonly surface?: string;
}

export type NodeFormDialogMode = 'add' | 'edit';

export interface NodesFormDialogData extends DialogData {
  mode: NodeFormDialogMode;
  nodeData?: NodeInfo;
  existingNodes?: NodeInfo[];
}

export interface Line {
  readonly name?: string;
  readonly energyVectorId?: string;
  readonly originNodeId?: string;
  readonly destinationNodeId?: string;
  readonly distance?: string;
  readonly bidirectional?: boolean;
  readonly efficiency?: string;
  readonly minSize?: string;
  readonly maxSize?: string;
  readonly forcedInvestment?: boolean;
  readonly buildCost?: string;
  readonly indivisibleCost?: string;
  readonly fomCharge?: string;
  readonly fomInstall?: string;
  readonly technicalLife?: number;
  readonly depreciationTime?: number;
  readonly library?: any;
  readonly sourceType?: string;
  readonly yearlyEfficiency?: YearlyValues;
  readonly yearlyBuildEmissionsKwm?: YearlyValues;
  readonly yearlyBuildEmissionsIndivisible?: YearlyValues;
  readonly yearlyMinSize?: YearlyValues;
  readonly yearlyMaxSize?: YearlyValues;
  readonly yearlyBuildCost?: YearlyValues;
  readonly yearlyIndivisibleCost?: YearlyValues;
  readonly yearlyFomCharge?: YearlyValues;
  readonly yearlyFomInstall?: YearlyValues;
  readonly yearlyTechnicalLife?: YearlyValues;
  readonly yearlyDepreciationTime?: YearlyValues;
  readonly existingAsset?: boolean;
  readonly capacityExpansion?: boolean;
}

export interface TopologyForm {
  readonly nodes?: Array<Node>;
  readonly lines?: Array<Line>;
}
