import {
  ListSubResourcesParams as ListSubResourcesFull,
  SingleSubResourceParams as SingleSubResourceFull,
} from '../endpoint-provider';

export type ListSubResources = Omit<
  ListSubResourcesFull,
  'project' | 'case' | 'scenario'
>;
export type SingleSubResource = Omit<
  SingleSubResourceFull,
  'project' | 'case' | 'scenario'
>;
