// Plotly Models
export interface PlotlyData {
  data: PlotlySankeyData[];
  layout?: unknown;
}

export interface PlotlySankeyData {
  readonly type: string;
  readonly orientation: 'h' | 'v';
  readonly valueformat: string;
  readonly valuesuffix: string;
  readonly node: {
    readonly pad: number;
    readonly thickness: number;
    readonly line: {
      readonly color: string;
      readonly width: number;
    };
    readonly label: string[];
    readonly color: string[];
  };
  readonly link: {
    readonly source: number[];
    readonly target: number[];
    readonly value: number[];
    readonly color: string[];
  };
}
