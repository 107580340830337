import { Observable } from 'rxjs';
import {
  EnergyGridLimit,
  EnergyGridLimitInterval,
} from '../../+scenario/models/commodities.model';
import {
  DuplicateDetailData,
  DetailEntity,
  HasId,
  HasDataType,
  HasScenarioUuid,
  ScenarioDetailState,
} from '../scenario-detail';

export type MarketLimit = EnergyGridLimit & DetailEntity;
export type MarketLimitForm = MarketLimit;
export type MarketLimitState = ScenarioDetailState<MarketLimit>;

export type MarketLimitBE = Partial<HasId> &
  Partial<HasScenarioUuid> &
  Partial<HasDataType> & {
    marketId: string;
    nodeId: NodeIds | string;
    marketLimitsInterval?: Array<EnergyGridLimitInterval>;
  };

interface NodeIds {
  nodeIds?: string[];
}

export type MarketLimitIdentifier = Pick<MarketLimit, 'market' | 'nodes'>;
export type MarketLimitDuplicateParams = Pick<MarketLimit, 'market' | 'nodes'>;
export type MarketLimitDuplicateDialogData =
  DuplicateDetailData<MarketLimit> & {
    existingEnergyGridLimits$: Observable<Array<EnergyGridLimit>>;
  };
