import { YearlyValues } from 'prosumer-shared';

export interface Limits {
  readonly yearlyMaximumEmissions?: YearlyValues;
  readonly yearlyMaximumCosts?: YearlyValues;
  readonly globalMaximumEmissions?: number;
  readonly globalMaximumCosts?: number;
}

export interface Options {
  readonly mipGap?: string;
  readonly limitRunHour?: string;
  readonly preventSimultaneousCharge?: number;
  readonly lcox?: number;
}

export interface CustomOptions {
  readonly name: string;
  readonly value: string;
}

export interface OptimizationCockpitForm {
  readonly limits?: Limits;
  readonly options?: Options;
  readonly customOptions?: CustomOptions[];
}
