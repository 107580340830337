export enum FilledStatus {
  full = 'full',
  half = 'half',
  empty = 'empty',
}

export enum ScenarioWizardStep {
  general = 'general',
  timeHorizon = 'timeHorizon',
  topology = 'topology',
  loads = 'loads',
  equipment = 'equipment',
  commodities = 'commodities',
  mobility = 'mobility',
  frequencyControl = 'reserves',
  netting = 'netting',
  regulation = 'regulation',
  optimizationCockpit = 'optimizationCockpit',
  launch = 'launch',
}

export interface WizardStepCriteria {
  readonly removeMobility: boolean;
  readonly removeNetting: boolean;
  readonly removeFrequencyControl: boolean;
  readonly removeTopology: boolean;
}
