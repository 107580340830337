import {
  EnergyVector,
  Equipment,
  EquipmentReserve,
} from 'prosumer-app/+scenario/models';
import { DialogData } from 'prosumer-app/libs/eyes-shared';
import { HasId } from 'prosumer-app/stores/scenario-detail';

export interface ReserveEquipmentFormDialogData extends DialogData {
  mode: 'add' | 'edit' | 'view';
  energyVectorOptions: Array<EnergyVector>;
  equipmentOptions: Array<Equipment>;
  currentReserveEquipment: EquipmentReserve & Partial<HasId>;
  currentReserveEquipmentList: Array<EquipmentReserve>;
}
