export interface Period {
  readonly year?: number;
  readonly startDate?: string;
  readonly endDate?: string;
  readonly weight?: string;
  readonly blockLength?: number;
}
export interface TimeForm {
  readonly projectDuration?: Array<number>;
  readonly yearsToOptimize?: Array<number>;
  readonly timeHorizon?: string;
  readonly timeHorizonPeriods?: Array<any>;
}
