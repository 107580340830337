import { YearlyValues } from 'prosumer-shared';

export interface Metering {
  readonly id?: string;
  readonly technologyId?: string;
  readonly netting?: string;
  readonly autoConsumptionTax?: string;
  readonly generationTariff?: string;
  readonly feedInTariff?: string;
  readonly feedInPremium?: string;
  // Yearly Values
  readonly yearlyAutoConsumptionTax?: YearlyValues;
  readonly yearlyGenerationTariff?: YearlyValues;
  readonly yearlyFeedInTariff?: YearlyValues;
  readonly yearlyFeedInPremium?: YearlyValues;
}

export interface RegulationsForm {
  readonly meterings?: Array<Metering>;
}
