import { ComparisonTray } from '@prosumer/comparison/base';
import {
  EmissionsData,
  EquipmentResult,
  TopologyResult,
} from '@prosumer/results/models';
import { Result } from 'prosumer-app/+scenario/models';
import { FormFieldOption } from 'prosumer-app/libs/eyes-shared';
import { ScenarioResult } from 'prosumer-scenario';

export interface ScenarioCompareState {
  readonly resultList: Array<Result>;
  readonly referenceId: string;
  readonly scenarioResultList: Array<ScenarioResult>;

  readonly dispatchTypes: Array<FormFieldOption<string>>;

  readonly loading?: boolean;
  readonly loaded?: boolean;
  readonly message?: string;
  readonly error?: any;
  readonly isOutputSplit?: boolean;
  readonly equipmentsResult?: ComparisonTray<EquipmentResult>;
  readonly topologiesResult?: ComparisonTray<TopologyResult>;
  readonly emissionsResult?: ComparisonTray<EmissionsData>;
}
