import { YearlyValues } from 'prosumer-shared';

import { HasTDBProfileFilters } from '../../shared/modules/tdb/models';
import { BinaryDelete, Profile } from './binary-data.model';

export interface EnergyGridConnection extends Partial<HasTDBProfileFilters> {
  readonly id?: string;
  readonly marketName?: string;
  readonly nodes?: Array<string>;
  readonly delivery?: string;
  readonly type?: string;
  readonly scenarioVariation?: string;
  readonly capacityTariff?: YearlyValues;
  readonly connectionTariff?: YearlyValues;
  // Profile intervals
  readonly profiles?: Array<Profile>;
}

export interface EnergyGridConnectionsEmissions
  extends Partial<HasTDBProfileFilters> {
  readonly id?: string;
  readonly marketName?: string;
  readonly node?: string;
  readonly scope?: string;
  readonly profiles?: Array<Profile>;
}

export interface EnergyGridLimit {
  readonly id?: string;
  readonly market?: string;
  readonly nodes?: Array<string>;
  readonly energyGridLimitIntervals?: Array<EnergyGridLimitInterval>;
}

export interface EnergyGridLimitInterval {
  readonly minPower?: string;
  readonly minEnergy?: string;
  readonly minCapacity?: string;
  readonly maxPower?: string;
  readonly maxEnergy?: string;
  readonly maxCapacity?: string;
  readonly startYear?: number;
  readonly endYear?: number;
  readonly yearOptions?: Array<number>;
}

export interface Fuel {
  readonly fuel?: string;
  readonly nodes?: Array<string>;
  readonly fuelCost?: YearlyValues;
  readonly co2Rate?: YearlyValues;
  readonly co2Scope3EmissionFactor?: YearlyValues;
}

export interface Emissions {
  readonly co2Price?: YearlyValues;
  readonly co2Scope2Price?: YearlyValues;
  readonly co2Scope3Price?: YearlyValues;
}

export interface CommoditiesForm extends BinaryDelete {
  readonly grids?: Array<EnergyGridConnection>;
  readonly connectionsEmissions?: Array<EnergyGridConnectionsEmissions>;
  readonly limits?: Array<EnergyGridLimit>;
  readonly fuels?: Array<Fuel>;
  readonly co2Price?: YearlyValues; // CO2 Scope 1 Price
  readonly co2Scope2Price?: YearlyValues;
  readonly co2Scope3Price?: YearlyValues;
}
