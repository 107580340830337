<form
  [formGroup]="uploadForm"
  fxLayout="column"
  fxLayoutGap="5px"
  #ngForm
  (ngSubmit)="onSubmit()"
>
  <prosumer-upload-form-list
    [multipleFiles]="true"
    [hideTableHeader]="false"
    [hideTableMessages]="false"
    [columnDef]="columnDef"
    [customTableDataActive]="true"
    [setCustomTableData]="tableData()"
    [acceptedFiles]="allowedFileExtns"
    [disabledTable]="isFormSubmitted"
    [disabledFileCtrl]="isFormSubmitted"
    [formTitle]="'Scenario.upload.inputFileForm.formTitle' | translate"
    (valueChanged)="inputFilesChanged($event)"
    (editableCellChanged)="onEditableCellChange($event)"
  ></prosumer-upload-form-list>

  <p class="mat-caption">
    {{ 'Scenario.information.missingTemplatePart1' | translate }}
    <a
      data-testid="scenario-download-link"
      class="eyes-href-bold custom-download-template-link"
      (click)="getXlsxlTemplate()"
    >
      {{ 'Scenario.information.missingTemplateLink' | translate }}
      <mat-icon
        aria-hidden="false"
        class="material-icons-outlined"
        tooltipPosition="center"
        aria-label="Download Excel Template"
        title="Download Excel Template"
      >
        save_alt
      </mat-icon>
    </a>
    {{ 'Scenario.information.missingTemplatePart2' | translate }}
  </p>

  <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start left">
    <button
      mat-flat-button
      class="eyes-button"
      color="primary"
      [disabled]="isLoading || uploadForm?.invalid || isFormSubmitted"
      data-testid="scenario-upload-simulate-button"
    >
      {{ 'Generic.labels.simulate' | translate }}
    </button>
    <button
      mat-flat-button
      class="eyes-button"
      type="button"
      [disabled]="isLoading"
      (click)="onCancelClicked()"
      data-testid="scenario-upload-cancel-button"
    >
      @if (isFormSubmitted && !isLoading) {
        <span>Continue</span>
      } @else {
        <span>Cancel</span>
      }
    </button>
  </div>
</form>
