import { ActivationReserve, EnergyVector } from 'prosumer-app/+scenario/models';
import { DialogData } from 'prosumer-app/libs/eyes-shared';
import { HasId } from 'prosumer-app/stores';

export interface ReserveActivationFormDialogData extends DialogData {
  mode: 'add' | 'edit' | 'view';
  energyVectorOptions: Array<EnergyVector>;
  currentReserveActivation: ActivationReserve & Partial<HasId>;
  currentReserveActivationList: Array<ActivationReserve>;
}
