import { InjectionToken } from '@angular/core';
import { WebSocketConfig } from '@oculus/utils/models';

export const ENV_PRODUCTION = new InjectionToken<boolean>('env-production');

export const ENV_API_URL = new InjectionToken<string>('env-api-url');

export const WEB_SOCKET_CONFIG = new InjectionToken<
  WebSocketConfig | undefined
>('web-socket-config');

export const WEB_SOCKET_URL = new InjectionToken<
  WebSocketConfig['url'] | undefined
>('web-socket-url');

export const WS_CONNECTION_TTL = new InjectionToken<
  WebSocketConfig['ttlConnection'] | undefined
>('web-socket-ttl');
