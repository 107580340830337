import { Cartographic, MapData } from '@eyes/eyes-gis';
import { GISCoordinatesService } from 'prosumer-app/+renewableprofile/services/gis-coordinates.service';
import { BaseComponent, generateUuid } from 'prosumer-app/libs/eyes-shared';
import { Observable, combineLatest } from 'rxjs';
import { filter, map, skip, takeUntil } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  OnInit,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'prosumer-coordinates-map',
  templateUrl: './coordinates-map-form.component.html',
  styleUrls: ['./coordinates-map-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoordinatesMapComponent
  extends BaseComponent
  implements OnInit, AfterContentInit
{
  currentView$: Observable<Cartographic>;
  coordinates$: Observable<MapData>;

  coordinatesForm: UntypedFormGroup = this.formBuilder.group({
    longitude: undefined,
    latitude: undefined,
  });

  constructor(
    public formBuilder: UntypedFormBuilder,
    private http: HttpClient,
    public coordinates: GISCoordinatesService,
  ) {
    super();
  }

  ngOnInit() {
    this.subscribeToCoordinatesService();
    this.coordinatesForm.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((coordinates) => {
        const { longitude, latitude } = coordinates;
        this.coordinates.setLatitude(latitude);
        this.coordinates.setLongitude(longitude);
      });
  }

  ngAfterContentInit() {
    this.currentView$ = combineLatest([
      this.coordinates.longitude$,
      this.coordinates.latitude$,
    ]).pipe(
      skip(1),
      filter(([longitude, latitude]) => !!longitude || !!latitude),
      takeUntil(this.componentDestroyed$),
      map(([longitude, latitude]) => ({
        altitude: 7127,
        longitude,
        latitude,
      })),
    );

    this.coordinates$ = combineLatest([
      this.coordinates.longitude$,
      this.coordinates.latitude$,
    ]).pipe(
      skip(1),
      filter(([longitude, latitude]) => !!longitude || !!latitude),
      takeUntil(this.componentDestroyed$),
      map(([longitude, latitude]) => {
        const uuid = generateUuid();
        const coordinate = {
          lines: [],
          polygon: [],
          nodes: [],
          pin: {
            hidden: false,
            id: uuid,
            name: 'Pin-' + uuid.substr(uuid.length - 5),
            pinTypeId: 'pin',
            position: {
              altitude: 0,
              longitude,
              latitude,
            },
            properties: {
              pinTypeId: 'pin',
            },
          },
        };
        return coordinate;
      }),
    );
  }

  dataChanges(mapData: MapData) {
    if (!!!mapData) {
      return;
    }

    const { pin } = mapData;
    const { longitude, latitude } = (pin || {}).position || {
      longitude: undefined,
      latitude: undefined,
    };

    if (!!!longitude || !!!latitude) {
      return;
    }

    if (
      longitude !== this.coordinatesForm.controls.longitude.value ||
      latitude !== this.coordinatesForm.controls.latitude.value
    ) {
      this.coordinates.setLatitude(latitude);
      this.coordinates.setLongitude(longitude);
    }
  }

  private subscribeToCoordinatesService(): void {
    this.coordinates.longitude$
      .pipe(skip(1), takeUntil(this.componentDestroyed$))
      .subscribe((longitude) => {
        this.coordinatesForm
          .get('longitude')
          .patchValue(longitude, { emitEvent: false });
      });
    this.coordinates.latitude$
      .pipe(skip(1), takeUntil(this.componentDestroyed$))
      .subscribe((latitude) => {
        this.coordinatesForm
          .get('latitude')
          .patchValue(latitude, { emitEvent: false });
      });
  }
}
