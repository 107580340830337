import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class GISCoordinatesService {
  private latitude = new BehaviorSubject<number>(undefined);
  private longitude = new BehaviorSubject<number>(undefined);
  private altitude = new BehaviorSubject<number>(undefined);

  latitude$: Observable<number>;
  longitude$: Observable<number>;
  altitude$: Observable<number>;

  setLatitude(latitude: number): void {
    this.latitude.next(latitude);
  }

  setLongitude(longitude: number): void {
    this.longitude.next(longitude);
  }

  setAltitude(altitude: number): void {
    this.altitude.next(altitude);
  }

  constructor() {
    this.latitude$ = this.latitude.asObservable();
    this.longitude$ = this.longitude.asObservable();
    this.altitude$ = this.altitude.asObservable();
  }
}
