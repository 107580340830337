import { TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DIALOG_SIZES } from 'prosumer-app/app.references';
import { DialogService } from 'prosumer-app/libs/eyes-core';
import { BaseDialogComponent, contains } from 'prosumer-app/libs/eyes-shared';
import { Observable } from 'rxjs';
import {
  EnergyGridConnectionsEmissions,
  MarketReserve,
  Scenario,
  reserveToString,
} from '../models';

@Injectable()
export class EnergyGridCascaderService {
  constructor(
    private _dialog: DialogService,
    private _titleCase: TitleCasePipe,
    private _translate: TranslateService,
  ) {}

  generateAffectedMessage([reserves, emissions]: [
    MarketReserve[],
    EnergyGridConnectionsEmissions[],
  ]) {
    const affectedReserves = reserves.map(
      (reserve) => `<li>${reserveToString(reserve)}</li>`,
    );
    const affectedEmissions =
      (emissions || []).length > 0
        ? `<li>${emissions.length} Energy Grid Connections Emissions</li>`
        : '';
    if (affectedReserves.length === 0 && affectedEmissions.length === 0) {
      return '';
    }

    const affectedMessage =
      `<div class="list-wrapper"><b>Affected Data</b>` +
      `${
        emissions.length > 0
          ? `<br>Commodities - Energy Grid Connections Emissions<br>` +
            `<ul>${affectedEmissions}</ul>`
          : ''
      }` +
      `${
        affectedReserves.length > 0
          ? `<br>Spinning Reserves - Reserve Market<br><ul>${affectedReserves}</ul>`
          : ''
      }` +
      `</div>`;

    return affectedMessage;
  }

  generateMessage(
    base: string,
    deletedNames?: Array<string>,
    affectedMessage?: string,
  ) {
    let deletedStr = '';
    deletedNames.forEach(
      (name) => (deletedStr += `<li>${this._titleCase.transform(name)}</li>`),
    );
    return `${base}<div class="list-wrapper"><b>To Delete</b><ul>${deletedStr}</ul></div>${affectedMessage}`;
  }

  showDeletedDialog(
    deletedMarketNames: Array<string>,
    affectedMessage?: string,
  ): Observable<any> {
    return this._dialog.openDialog(BaseDialogComponent, {
      title: this._translate.instant(
        'Scenario.dialog.selectionDeleted.market.title',
      ),
      message: this.generateMessage(
        this._translate.instant(
          'Scenario.dialog.selectionDeleted.market.message',
        ),
        deletedMarketNames,
        affectedMessage,
      ),
      confirm: this._translate.instant('Generic.labels.yes'),
      close: this._translate.instant('Generic.labels.no'),
      width: DIALOG_SIZES.small,
    });
  }

  cascadeMarketDeletion(
    scenario: Scenario,
    deletedMarketIds: Array<string>,
  ): Scenario {
    if (!!!scenario) {
      return;
    }
    const reserveMarkets = (
      ((scenario || {}).frequencyControl || {}).spinningReserve || {}
    ).markets;
    const connectionsEmissions = (scenario || {}).commodities
      .connectionsEmissions;
    const cascadedReserveMarkets = this.filterReserveMarkets(
      reserveMarkets,
      deletedMarketIds,
    );
    const cascadedConnectionsEmissions = this.filterConnectionsEmissions(
      connectionsEmissions,
      deletedMarketIds,
    );
    return {
      ...scenario,
      frequencyControl: {
        ...scenario.frequencyControl,
        spinningReserve: {
          ...scenario.frequencyControl.spinningReserve,
          markets: cascadedReserveMarkets,
        },
      },
      commodities: {
        ...scenario.commodities,
        connectionsEmissions: cascadedConnectionsEmissions,
      },
    };
  }

  filterReserveMarkets = (
    markets: MarketReserve[],
    deletedMarketIds: string[],
  ): MarketReserve[] =>
    markets.filter(
      (reserve) => !contains(deletedMarketIds, reserve.energyGridConnectionId),
    );

  filterConnectionsEmissions = (
    emissions: EnergyGridConnectionsEmissions[],
    deletedMarketIds: string[],
  ): EnergyGridConnectionsEmissions[] =>
    emissions.filter(
      (emission) => !contains(deletedMarketIds, emission.marketName),
    );
}
