import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { LetDirective, PushPipe } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';
import { DialogDivModule } from 'prosumer-shared/components/dialog-div';
import { GenericFilterchipModule } from 'prosumer-app/shared/modules/generic-filterchip';
import { MarketLimitDuplicateDialogComponent } from './market-limit-duplicate-dialog.component';

@NgModule({
  declarations: [MarketLimitDuplicateDialogComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    GenericFilterchipModule,
    MatSelectModule,
    MatIconModule,
    LetDirective,
    PushPipe,
    TranslateModule,
    MatButtonModule,
    DialogDivModule,
    GenericFilterchipModule,
  ],
  exports: [MarketLimitDuplicateDialogComponent],
})
export class MarketLimitDuplicateDialogModule {}
