/* eslint-disable @typescript-eslint/naming-convention */
import {
  MainOutputResult,
  EquipmentResult,
  TopologyResult,
  EnergyBalanceResult,
  CashflowData,
  EmissionResult,
  FlowResult,
} from '../../results/models';

export type ResultState = Readonly<{
  latestScenarioVersion: string;
  loading?: boolean;
  error?: any;
  currentScenarioID?: string;
  currentScenarioVariationID?: string;
  data?: any;
  chartDataMapping?: Array<{ chartId: string; chartData: any }>;
  systemVisualization?: any;
  errorSystemVisualization?: any;
  isOutputSplit?: boolean;
  optimized_years: Array<string>;
  all_years: Array<number>;
  isMultiNode: boolean;
  mainData?: {
    Main_outputs: Record<string, MainOutputResult>;
  };
  equipmentData?: {
    DER_investments: Record<string, EquipmentResult>;
    all_years: Array<number>;
  };
  topologyData?: {
    line_investments: Record<string, TopologyResult>;
    all_years: Array<number>;
  };
  energyBalanceData?: {
    energy_balance: Record<string, Record<string, EnergyBalanceResult>>;
    all_years: Array<number>;
  };
  cashFlowData?: {
    cumulative_cashflow: Record<string, Record<string, number>>;
    incremental_cashflow: Record<string, Record<string, number>>;
  };
  co2EmissionsData?: any;
  dispatchData?: {
    dispatch: Record<string, unknown>;
  };
  flowsData?: any;
}>;
