import { Component, Input } from '@angular/core';
import { PerceptionMap } from '@prosumer/results/components/case-results-perception';
import { ResultNameValue } from '@prosumer/results/components/results-visualizer';
import { ENGIE_DEFAULT_SCHEME } from 'prosumer-app/app.references';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FilterableLegend } from '../../base';
import { NgxChartUtils } from '../../utils';

@Component({
  selector: 'prosumer-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
})
export class PieChartComponent extends FilterableLegend {
  private dataSubject = new BehaviorSubject<unknown[]>([]);
  private colorMapSubject = new BehaviorSubject<PerceptionMap>({});
  private chartSchemeSubject = new BehaviorSubject<unknown>(
    ENGIE_DEFAULT_SCHEME,
  );

  @Input() set colorMap(colorMap: PerceptionMap) {
    this.colorMapSubject.next(colorMap);
  }
  @Input() set data(data: unknown[]) {
    this.dataSubject.next(data || []);
  }
  @Input() view: [number, number];
  @Input() legendPosition: 'right' | 'below' = 'right';
  @Input() showLegend = true;

  chartScheme$: Observable<unknown>;

  constructor() {
    super();
    this.chartScheme$ = this.chartSchemeSubject.asObservable();

    this.subscribeToChartDataAndColorMapForSchemeSync();
  }

  getChartDataStream(): Observable<unknown[]> {
    return this.dataSubject.asObservable();
  }
  getLegendNames(data: unknown[]): string[] {
    return data.map((d) => d['name']);
  }
  mutateSingleChartDataBasedOnLegend<T>(single: T): T {
    const name = single['name'];
    const value = single['value'];
    return {
      ...single,
      name: this.mutateNameBasedOnShowOrHide(name),
      value: this.shouldShowData(name) ? value : 0,
    };
  }

  onSelect(data: unknown): void {
    this.onLegendSelect(data);
  }

  private subscribeToChartDataAndColorMapForSchemeSync(): void {
    combineLatest([this.dataSubject, this.colorMapSubject])
      .pipe(
        map(([data, colorMap]) =>
          NgxChartUtils.generatePieColorScheme(
            data as ResultNameValue[],
            colorMap,
          ),
        ),
      )
      .subscribe((scheme) => this.chartSchemeSubject.next(scheme));
  }
}
