import { EnergyVector, MarginReserve } from 'prosumer-app/+scenario/models';
import { DialogData } from 'prosumer-app/libs/eyes-shared';
import { HasId } from 'prosumer-app/stores';

export interface ReserveMarginFormDialogData extends DialogData {
  mode: 'add' | 'edit' | 'view';
  energyVectorOptions: Array<EnergyVector>;
  currentReserveMargin: MarginReserve & Partial<HasId>;
  currentReserveMarginList: Array<MarginReserve>;
  startYear: number;
  endYear: number;
  projectId: string;
  caseId: string;
  scenarioId: string;
}
