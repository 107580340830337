import { BaseEntityState } from 'prosumer-app/libs/eyes-shared';
import { RenewableProfile, UserRenewableProfile } from '../models';

export interface RenewableProfileState
  extends BaseEntityState<RenewableProfile> {
  userProfiles?: UserRenewableProfile[];
  renewableProfiles?: RenewableProfile[];
  downloading?: boolean;
  submitting?: boolean;
}
