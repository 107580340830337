import { YearlyValues } from 'prosumer-app/shared/models';

export interface TDBGetEVsResponse {
  types: string[];
}

export interface TDBGetFiltersResponse {
  filters: TDBFilters;
}

export interface TDBGetPricesResponse {
  type: string;
  year: string;
  geography: string;
  scenario: TDBPricesScenarios;
  granularity: TDBPricesGranularity;
  values: number[];
}

export interface TDBGetPriceIntervalsResponse {
  type: string;
  geography: string;
  scenario: TDBPricesScenarios;
  granularity: TDBPricesGranularity;
  values: Record<number, string>;
}

export interface TDBFilters {
  geography?: string[];
  scenario?: TDBPricesScenarios[];
  year?: number[];
  unit?: string[];
}

export enum TDBPricesScenarios {
  cenNominal = 'CEN - nominal',
  cenReal = 'CEN - real',
  decNominal = 'DEC - nominal',
  decReal = 'DEC - real',
}

export enum TDBPricesGranularity {
  hourly = 'hourly',
  yearly = 'yearly',
}

export interface TDBGetPricesQueryParameters {
  year: number;
  geography: string;
  scenario: TDBPricesScenarios;
  granularity?: TDBPricesGranularity;
  unit: string;
}

export interface TDBCommoditiesFormSelect {
  name: string;
  value: string;
}

export interface TdbActiveFilters extends Record<string, string | number> {
  readonly filterDate?: string;
  readonly commodity?: string;
  readonly geography?: string;
  readonly scenario?: string;
  readonly unit?: string;
  readonly updatedAt?: string;
}

export interface EVPartialTDBTechnologyDataModel {
  inputEnergyVector1?: string;
  outputEnergyVector1?: string;
  efficiency1?: YearlyValues;
  inputEnergyVector2?: string;
  outputEnergyVector2?: string;
  efficiency2?: YearlyValues;
}
