import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ScenarioGenericStore } from '../../../stores/scenario-generic';
import { Case } from 'prosumer-app/+case/models';

@Injectable({
  providedIn: 'root',
})
export class ScenarioInfoService {
  constructor(private readonly store: ScenarioGenericStore) {}

  getScenario(): Observable<unknown> {
    this.store.setActive(null);
    return this.store.handleAdd(this.store.getInfo());
  }

  createScenario(name: string, caseData?: Case): Observable<unknown> {
    return this.store.handleAdd(
      this.store.postInfo('create', {
        ...this.getScenarioDefaults(caseData),
        name,
      }),
    );
  }

  updateScenario(data: unknown, forced: boolean = false): Observable<unknown> {
    if (!!forced) {
      return this.store.handleUpdate(this.store.postInfo('forceUpdate', data));
    }
    return this.store.handleUpdate(this.store.postInfo('update', data));
  }

  private getScenarioDefaults(caseData: Case): { [key: string]: unknown } {
    return {
      economics: {
        inflationRate: '0.0',
        taxDepreciation: '0.0',
        taxRevenue: '0.0',
        wacc: caseData.wacc ?? '0.0',
      },
      timeHorizon: { fullTimeHorizon: true, periods: [] },
      projectDuration: { duration: 20, startYear: new Date().getFullYear() },
      energyVectors: [],
      emissions: {},
    };
  }
}
