import { ValidatorFn } from '@angular/forms';

export type DefinitionTypes =
  | 'action'
  | 'reference'
  | 'custom'
  | 'chart'
  | 'referenceList'
  | 'referenceLink'
  | 'textOverflow'
  | 'referenceLinkWithAction'
  | 'selection'
  | 'date'
  | 'number'
  | 'currency'
  | 'statusIcon'
  | 'editableValue';

export interface Definition {
  readonly name?: string;
  readonly type?: DefinitionTypes;
  readonly referenceKey?: string; // Must be supplied if type is reference or referenceList
  readonly sortable?: boolean;
  readonly toolTip?: string;
  readonly actionButtons?: Array<ActionButton>;
  readonly alignment?: 'flex-start' | 'center' | 'flex-end';
  readonly eyesClick?: any;
  readonly flex?: string;
  readonly hideTitle?: boolean;
  readonly validators?: ValidatorFn[];
  readonly format?: string | ((...args: any[]) => string);
}

export interface ActionButton {
  iconPrefix?: string;
  tooltip?: string;
  iconSuffix?: string;
  label?: string; // Set to 'default' if want to use column value.
  link?: string | Array<any>;
  type?: string;
  disabled?: boolean;
  color?: string;
  eyesClick?: any;
  action?: string;
}

export interface ColumnDefinition {
  [key: string]: Definition;
}
