import moment from 'moment';
import { CompareDispatchResultItem, Scenario } from 'prosumer-scenario/models';

export interface CompareDispatchData extends Scenario {
  readonly dataList: Array<CompareDispatchResultItem>;
  readonly start?: number;
  readonly end?: number;
}

export interface CompareDispatchChartSeriesData {
  readonly name?: string;
  readonly value: number;
  readonly moment?: moment.Moment;
  readonly hour?: number;
}

export interface CompareDispatchChartData {
  readonly name: string;
  readonly series: Array<CompareDispatchChartSeriesData>;
}
