import { filter, map, Observable, shareReplay, throwError } from 'rxjs';

import { HttpErrorResponse } from '@angular/common/http';
import { Directive } from '@angular/core';
import { select, Store, StoreDef } from '@ngneat/elf';
import {
  addEntities,
  deleteEntities,
  EntitiesRef,
  EntitiesState,
  getActiveEntity,
  getActiveId,
  getAllEntities,
  getAllEntitiesApply,
  getEntitiesCountByPredicate,
  getEntity,
  hasEntity,
  resetActiveId,
  selectActiveEntity,
  selectActiveId,
  selectAllEntities,
  selectAllEntitiesApply,
  selectEntity,
  selectMany,
  setActiveId,
  setEntities,
  updateEntities,
  upsertEntities,
  upsertEntitiesById,
} from '@ngneat/elf-entities';
import { Entity, State } from '@oculus/utils/models';

@Directive({})
export abstract class EntityStateRepository<
  T extends State &
    EntitiesState<EntitiesRef<'entities', 'ids', 'idKey'>> & {
      activeId: U['id'];
    },
  U extends Entity,
> {
  state$ = this.store;
  loading$ = this.store.pipe(select((state) => state.loading));
  loaded$ = this.store.pipe(select((state) => state.loaded));
  saving$ = this.store.pipe(select((state) => state.saving));
  deleting$ = this.store.pipe(select((state) => state.deleting));
  error$ = this.store.pipe(select((state) => state.error));
  entities$ = this.store.pipe(selectAllEntities());

  selectedEntity$ = this.store.pipe(
    selectActiveEntity(),
    shareReplay({ refCount: true, bufferSize: 1 }),
  ) as Observable<U>;

  selectedEntityLoading$ = this.selectedEntity$.pipe(
    map((entity) => !!entity?.loading),
  );

  selectedEntityLoaded$ = this.selectedEntity$.pipe(
    map((entity) => !!entity?.loaded),
  );

  selectedEntitySaving$ = this.selectedEntity$.pipe(
    map((entity) => !!entity?.saving),
  );

  selectedEntityError$ = this.selectedEntity$.pipe(
    map((entity) => entity?.error),
  );

  selectActiveId$ = this.store.pipe(
    selectActiveId(),
    filter((id) => !!id),
  );

  get selectActiveId(): U['id'] {
    return this.store.query<U['id']>(getActiveId);
  }

  get selectedEntity(): U | undefined {
    return this.store.query<U | undefined>(getActiveEntity());
  }

  get entities(): Array<U> | [] {
    return this.store.query<Array<U> | []>(getAllEntities());
  }

  getEntity(id: U['id']): U {
    return this.store.query(getEntity(id)) as U;
  }

  hasEntity(id: U['id'] | undefined | string): boolean {
    if (!id) return false;
    return this.store.query<boolean>(hasEntity(id));
  }

  constructor(protected store: Store<StoreDef<T>>) {}

  setState(newState: Partial<T>) {
    this.store.update((state) => ({ ...state, ...newState }));
  }

  clearState() {
    this.store.update(() => this.store.initialState);
  }

  setEntities(entities: Array<U>) {
    this.store.update(setEntities(entities));
  }

  addEntity(entity: U) {
    this.store.update(addEntities(entity));
  }

  addEntities(entities: Array<U>) {
    this.store.update(addEntities(entities));
  }

  upsertEntities(entities: Array<U>) {
    this.store.update(upsertEntities(entities));
  }

  updateEntity(id: U['id'], entity: Partial<U>) {
    this.store.update(
      updateEntities(id, (prevValue) => ({ ...prevValue, ...entity })),
    );
  }

  updateEntities(ids: Array<U['id']>, data: Partial<U>) {
    this.store.update(updateEntities(ids, data));
  }

  upsertEntity(id: U['id'], entity: Partial<U>) {
    this.store.update(
      upsertEntitiesById(id, {
        updater: (prevValue) => ({ ...prevValue, ...entity }),
        creator: () => ({ id, ...entity }),
      }),
    );
  }

  deleteEntity(ids: Array<U['id']>): void {
    this.store.update(deleteEntities(ids));
  }

  resetEntities() {
    this.store.reset();
  }

  resetActiveId() {
    this.store.update(resetActiveId());
  }

  selectEntity(id: U['id'] | null) {
    this.store.update(setActiveId(id));
  }

  selectEntitiesByProps$(
    props: Partial<U>,
    keys: Array<keyof U> = [],
  ): Observable<Array<U>> {
    return this.store.pipe(
      selectAllEntitiesApply({
        mapEntity: (entity) =>
          keys.length ? this.pluckEntityMapper(entity, keys) : entity,
        filterEntity: (entity) => this.filterEntityPredicate(entity, props),
      }),
      shareReplay({ refCount: true }),
    );
  }

  selectMany$(ids: Array<U['id']>): Observable<Array<U>> {
    return this.store.pipe(selectMany(ids));
  }

  getEntitiesByProps(
    props: Partial<U>,
    keys: Array<keyof U> = [],
  ): Array<U> | [] {
    return this.store.query<Array<U> | []>(
      getAllEntitiesApply({
        mapEntity: (entity) =>
          keys.length ? this.pluckEntityMapper(entity, keys) : entity,
        filterEntity: (entity) => this.filterEntityPredicate(entity, props),
      }),
    );
  }

  getEntitiesCount(props: Partial<U> = {}): number {
    return this.store.query<number>(
      getEntitiesCountByPredicate((entity: U) =>
        props ? this.filterEntityPredicate(entity, props) : true,
      ),
    );
  }

  setEntityLoading(id: string) {
    this.upsertEntity(id, {
      loading: true,
      error: null,
    } as Partial<U>);
  }

  upsertEntitySuccess(entity: Partial<U>) {
    this.upsertEntity(
      entity.id as U['id'],
      {
        ...entity,
        loading: false,
        loaded: true,
        error: null,
      } as Partial<U>,
    );
  }

  setEntityError(id: string, error: HttpErrorResponse): Observable<never> {
    this.upsertEntity(id, {
      loading: false,
      loaded: false,
      error: `errorCodes.${error.error.error || error.status}`,
    } as Partial<U>);
    return throwError(() => error);
  }

  getEntity$(id: U['id'] | null): Observable<U> {
    return this.store.pipe(
      selectEntity(id),
      shareReplay({ refCount: true, bufferSize: 1 }),
    ) as Observable<U>;
  }

  pluckEntityMapper(entity: U, keys: Array<keyof U>): U {
    const partialEntity = {} as U;
    keys.forEach((key) => (partialEntity[key] = entity[key]));
    return partialEntity;
  }

  filterEntityPredicate(entity: U, props: Partial<U>): boolean {
    for (const key in props) {
      if (entity[key] === undefined || entity[key] !== props[key]) {
        return false;
      }
    }
    return true;
  }
}
