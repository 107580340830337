import { Utils } from 'prosumer-app/core';

import {
  CommoditiesForm,
  EnergyGridConnection,
  EnergyGridConnectionsEmissions,
  EnergyGridLimit,
  Equipment,
  EquipmentCircuitInfo,
  EquipmentReserve,
  Load,
  MarketReserve,
  Metering,
  MobilityForm,
  Netting,
  NettingForm,
  Node,
  Scenario,
  ShortCircuit,
  SpinningReserve,
  StationVehicleAssoc,
  TaxAndSubsidies,
  VehiclesDispatch,
} from '../models';

export class VariationCascaderExt {
  static resolveMarketReserves(scenario: Scenario): MarketReserve[] {
    return Utils.resolveToEmptyArray(this.resolveSpinReserve(scenario).markets);
  }

  static resolveMarketEquips(scenario: Scenario): EquipmentReserve[] {
    return Utils.resolveToEmptyArray(
      this.resolveSpinReserve(scenario).equipments,
    );
  }

  static resolveCircuitEquips(scenario: Scenario): EquipmentCircuitInfo[] {
    return Utils.resolveToEmptyArray(this.resolveCircuit(scenario).equipments);
  }

  static resolveCircuit(scenario: Scenario): ShortCircuit {
    return Utils.resolveToEmptyObject(
      Utils.resolveToEmptyObject(scenario.frequencyControl).shortCircuit,
    );
  }

  static resolveSpinReserve(scenario: Scenario): SpinningReserve {
    return Utils.resolveToEmptyObject(
      Utils.resolveToEmptyObject(scenario.frequencyControl).spinningReserve,
    );
  }

  static resolveGrids(scenario: Scenario): EnergyGridConnection[] {
    return Utils.resolveToEmptyArray(this.resolveCommodities(scenario).grids);
  }

  static resolveEquipments(scenario: Scenario): Equipment[] {
    return Utils.resolveToEmptyArray(
      Utils.resolveToEmptyObject(scenario.equipments).equipments,
    );
  }

  static resolveLimits(scenario: Scenario): EnergyGridLimit[] {
    return Utils.resolveToEmptyArray(this.resolveCommodities(scenario).limits);
  }

  static resolveLoads(scenario: Scenario): Load[] {
    return Utils.resolveToEmptyArray(
      Utils.resolveToEmptyObject(scenario.loads).loads,
    );
  }

  static resolveConnections(
    scenario: Scenario,
  ): EnergyGridConnectionsEmissions[] {
    return Utils.resolveToEmptyArray(
      this.resolveCommodities(scenario).connectionsEmissions,
    );
  }

  static resolveCommodities(scenario: Scenario): CommoditiesForm {
    return Utils.resolveToEmptyObject(scenario.commodities);
  }

  static resolveNodes(scenario: Scenario): Node[] {
    return Utils.resolveToEmptyArray(
      Utils.resolveToEmptyObject(scenario.topology).nodes,
    );
  }

  static resolveNettings(scenario: Scenario): Netting[] {
    return Utils.resolveToEmptyArray(this.resolveNetting(scenario).netting);
  }

  static resolveTaxAndSubsidies(scenario: Scenario): TaxAndSubsidies[] {
    return Utils.resolveToEmptyArray(
      this.resolveNetting(scenario).taxAndSubsidies,
    );
  }

  static resolveNetting(scenario: Scenario): NettingForm {
    return Utils.resolveToEmptyObject(scenario.netting);
  }

  static resolveMeterings(scenario: Scenario): Metering[] {
    return Utils.resolveToEmptyArray(
      Utils.resolveToEmptyObject(scenario.regulations).meterings,
    );
  }

  static resolveVehiclesDispatch(scenario: Scenario): VehiclesDispatch[] {
    return Utils.resolveToEmptyArray(this.resolveMobility(scenario).vehicles);
  }

  static resolveStationVehicleAssoc(scenario: Scenario): StationVehicleAssoc[] {
    return Utils.resolveToEmptyArray(
      this.resolveMobility(scenario).stationVehicleAssoc,
    );
  }

  static resolveMobility(scenario: Scenario): MobilityForm {
    return Utils.resolveToEmptyObject(scenario.mobility);
  }
}
