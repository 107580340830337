import { VehiclesDispatch } from 'prosumer-app/+scenario/models';
import { DialogData, FormFieldOption } from 'prosumer-app/libs/eyes-shared';
import { Observable } from 'rxjs';

export interface VehiclesDispatchFormDialogData
  extends VehiclesDispatch,
    DialogData {
  mode: 'add' | 'edit';
  isViewOnly?: boolean;
  routes: FormFieldOption<string>[];
  vehicles: FormFieldOption<string>[];
  scenarioStartYear: number;
  scenarioEndYear: number;
  currentData: VehiclesDispatch;
  existingList?: VehiclesDispatch[];
  currentDataList$?: Observable<VehiclesDispatch[]>;
  scenarioIdentity?: {
    projectId: string;
    caseId: string;
    scenarioId: string;
  };
}
