import { EntityState } from '@datorama/akita';
import { Profile as LegacyProfile } from 'prosumer-scenario/models';

export interface ProfileState extends EntityState<Profile> {}

export interface ProfileParents {
  readonly projectId: string;
  readonly caseId: string;
  readonly scenarioId: string;
}

export interface Profile extends ProfileParents {
  readonly id: string;
  readonly newLocalId: string;
  readonly location: string;
  readonly data: string[];
}

export interface HasProfiles {
  readonly profiles: Profile[];
}

export interface HasLegacyProfiles {
  readonly profiles: LegacyProfile[];
}

export interface ProfileGroup extends HasLegacyProfiles {
  readonly name: string;
}

export interface HasProfileGroup {
  readonly profile: ProfileGroup;
}
