<div fxLayout="column" fxLayoutGap="5px" class="sparkline-table-container">
  <div fxLayout="row">
    <h3 *ngIf="name" class="eyes-remove-margin">{{ name | translate }}</h3>

    <prosumer-search-input
      *ngIf="searchInput"
      fxFlex="50%"
      (keyIn)="onKeyIn($event)"
      data-testid="sparkline-search"
    ></prosumer-search-input>

    <span fxFlex></span>

    <prosumer-tooltip-button
      *ngIf="!hideAdd"
      [disabledAddBtnMessage]="disabledAddBtnMessage"
      [isBtnDisabled]="disableNewButton || loading || saving"
      [matIconLabel]="'add'"
      [buttonText]="'Generic.labels.new' | translate"
      data-testid="sparkline-add-tooltip-button-component"
      (clickEvent)="onAdd()"
    ></prosumer-tooltip-button>
  </div>

  <mat-table
    matSort
    class="eyes-table"
    [dataSource]="dataSource"
    [style.maxHeight]="maxHeight || 'none'"
    data-testid="sparkline-mat-table"
  >
    <ng-container
      *ngFor="let column of columnsDef | keyvalue; let l = last"
      [cdkColumnDef]="column.key"
    >
      <mat-header-cell
        *cdkHeaderCellDef
        mat-sort-header
        [ngSwitch]="column.value.type"
        [disabled]="!column.value.sortable"
        [ngClass]="selector + '-' + (column.value.alignment || 'flex-start')"
        [fxFlex]="column.value.flex"
      >
        <div
          [innerHTML]="column.value.name"
          [attr.data-testid]="'sparkline-table-header-' + column.value.name"
        ></div>

        <prosumer-tooltip-anchor
          *ngIf="column.value.toolTip"
          [message]="column.value.toolTip"
          [tooltipPosition]="!!l ? 'left' : 'center'"
          [attr.data-testid]="
            'sparkline-table-header-' + column.value.name + '-tooltip'
          "
        >
        </prosumer-tooltip-anchor>
      </mat-header-cell>

      <mat-cell
        *cdkCellDef="let row"
        [ngSwitch]="column.value.type"
        [ngClass]="[
          selector + '-' + (column.value.alignment || 'flex-start'),
          column.value.toolTip ? 'has-tooltip' : ''
        ]"
        [fxFlex]="column.value.flex"
        title="{{ column.value.hideTitle ? '' : row[column.key] }}"
      >
        <div *ngSwitchCase="'selection'">
          <mat-radio-button
            [disabled]="isViewOnly"
            [checked]="selection.isSelected(row[idField])"
            (change)="selection.select(row[idField])"
            (click)="$event.stopPropagation()"
            data-testid="sparkline-cell-radio-button"
          ></mat-radio-button>
        </div>

        <div *ngSwitchCase="'action'" class="sparkline-cell-action">
          <button
            *ngIf="!hideView"
            mat-icon-button
            type="button"
            color="primary"
            [class.spinner]="row['loading']"
            [disabled]="disabled || loading || saving || isLoadingStatus(row)"
            (click)="onEdit(row)"
            data-testid="sparkline-cell-view-action-button"
          >
            <mat-icon
              aria-hidden="false"
              aria-label="View"
              tooltipPosition="center"
              eyesTooltip="View"
              >visibility</mat-icon
            >
          </button>

          <button
            *ngIf="!hideEdit"
            mat-icon-button
            type="button"
            color="primary"
            [class.spinner]="row['loading']"
            [disabled]="
              disabled ||
              loading ||
              saving ||
              listItemLoading[row?.id] ||
              row['loading'] ||
              isLoadingStatus(row)
            "
            (click)="onEdit(row)"
            data-testid="sparkline-cell-edit-action-button"
          >
            <mat-icon
              aria-hidden="false"
              aria-label="Edit"
              tooltipPosition="center"
              eyesTooltip="Edit"
              >edit</mat-icon
            >
          </button>

          <button
            *ngIf="!hideDuplicate"
            mat-icon-button
            type="button"
            color="primary"
            [class.spinner]="isDuplicating(row)"
            [disabled]="
              disabled ||
              loading ||
              saving ||
              row['loading'] ||
              isLoadingStatus(row)
            "
            (click)="onDuplicate(row)"
            [attr.data-testid]="'sparkline-cell-duplicate-action-button'"
          >
            <mat-icon
              aria-hidden="false"
              aria-label="Duplicate & Edit"
              tooltipPosition="center"
              eyesTooltip="Duplicate & Edit"
            >
              file_copy
            </mat-icon>
          </button>

          <button
            *ngIf="!hideDelete"
            mat-icon-button
            type="button"
            color="primary"
            [disabled]="
              disabled ||
              loading ||
              saving ||
              listItemLoading[row?.id] ||
              row['loading'] ||
              isLoadingStatus(row)
            "
            (click)="onDelete(row)"
            data-testid="sparkline-cell-delete-action-button"
          >
            <mat-icon
              aria-hidden="false"
              aria-label="Delete"
              tooltipPosition="center"
              eyesTooltip="Delete"
              >delete_forever</mat-icon
            >
          </button>
        </div>

        <div
          *ngSwitchCase="'custom'"
          [prosumerTooltip]="
            getTooltipFromReferences(
              row[column.key],
              column.value.cellTooltipReferenceKey
            )
          "
        >
          {{
            (references[column.value.referenceKey] || {})[row[column.key]] ||
              row[column.key] ||
              (noDash ? '' : '-')
          }}
        </div>

        <div *ngSwitchCase="'reference'">
          {{
            (references[column.value.referenceKey] || {})[row[column.key]] ||
              row[column.key] ||
              (noDash ? '' : '-')
          }}
        </div>

        <div
          *ngSwitchCase="'referenceList'"
          class="list-reference"
          [prosumerTooltip]="
            getValueFromKeyList(row[column.key], column.value.referenceKey)
          "
        >
          {{
            getValueFromKeyList(row[column.key], column.value.referenceKey) ||
              (noDash ? '' : '-')
          }}
        </div>

        <div *ngSwitchCase="'date'">
          {{
            (row[column.key] | date: column.value.format) || (noDash ? '' : '-')
          }}
        </div>

        <div *ngSwitchCase="'number'">
          {{
            (row[column.key] | number: column.value.format) ||
              (noDash ? '' : '-')
          }}
        </div>

        <div *ngSwitchCase="'currency'">
          {{
            (row[column.key] | metric: column.value.format) ||
              (noDash ? '' : '-')
          }}
        </div>

        <div *ngSwitchCase="'chart'">
          <prosumer-yearly-sparkline
            *ngIf="hasMultipleValues(row[column.key]); else showFirstValue"
            [yearlyValuesMap]="row[column.key]"
            [smallerChart]="true"
          >
          </prosumer-yearly-sparkline>

          <ng-template #showFirstValue>
            {{ row[column.key] | firstValue }}
          </ng-template>
        </div>

        <div *ngSwitchCase="'statusIcon'">
          @switch (row[column.key]) {
            @case ('loading') {
              <mat-progress-spinner
                class=""
                [diameter]="20"
                [color]="'accent'"
                [mode]="'indeterminate'"
                data-testid="sparkline-table-status-loading"
              >
              </mat-progress-spinner>
            }
            @case ('succeeded') {
              <mat-icon
                class="material-icons-outlined success"
                data-testid="sparkline-table-status-succeeded"
                >check_circle_outline</mat-icon
              >
            }
            @case ('failed') {
              <mat-icon
                class="material-icons-outlined"
                color="warn"
                data-testid="sparkline-table-status-failed"
              >
                do_not_disturb_on</mat-icon
              >
            }
            @default {
              <span data-testid="sparkline-table-status-default">-</span>
            }
          }
        </div>

        <div *ngSwitchCase="'editableValue'">
          <prosumer-table-cell-editable
            [value]="row[column.key]"
            [disabled]="disabled"
            [validators]="column.value.validators"
            (valueChanged)="
              onEditableCellChange($event, row[column.key], column.key)
            "
            data-testid="sparkline-table-cell-editable"
          ></prosumer-table-cell-editable>
        </div>

        <div
          *ngSwitchDefault
          tooltipPosition="center"
          [prosumerTooltip]="
            cellTooltip ? row[column.key] || (noDash ? '' : '-') : null
          "
          [ngClass]="[noWrap ? selector + '-nowrap' : '']"
        >
          {{ row[column.key] ?? (noDash ? '' : '-') | ellipsesize: 80 }}
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row
      [ngClass]="hideHeader && 'disappear'"
      *cdkHeaderRowDef="columnsDef | keys; sticky: true"
    ></mat-header-row>

    <mat-row
      *cdkRowDef="let row; columns: columnsDef | keys"
      [@rowAnimation]
      [hidden]="loading"
      data-testid="sparkline-table-row"
    ></mat-row>
  </mat-table>
</div>

<div *ngIf="!hideMessages" [ngClass]="selector + '-message'">
  <div
    *ngIf="loading; else hasError"
    [@rowAnimation]
    data-testid="sparkline-loading"
  >
    <div>{{ messages.loading | translate }}</div>
  </div>

  <ng-template #hasError>
    <div
      *ngIf="error; else hasNoData"
      [@rowAnimation]
      data-testid="sparkline-error"
    >
      {{ messages.error | translate }}
    </div>
  </ng-template>

  <ng-template #hasNoData>
    <div
      *ngIf="dataSource?.data?.length === 0; else hasNoFilteredData"
      [@rowAnimation]
      data-testid="sparkline-noData"
    >
      {{ messages.noRecords | translate }}
    </div>
  </ng-template>

  <ng-template #hasNoFilteredData>
    <div
      *ngIf="dataSource?.filteredData?.length === 0"
      [@rowAnimation]
      data-testid="sparkline-noFilteredData"
    >
      {{ messages.noResults | translate }} '{{ dataSource?.filter }}'
    </div></ng-template
  >
</div>
