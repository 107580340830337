import { PerceptionMap } from '@prosumer/results/components/case-results-perception';
import { HasCreatedBy } from 'prosumer-core';

import { CaseEntity } from './case-entity.model';

// export type Economics = Readonly<{
//   wacc: string;
//   inflationRate: string;
//   taxDepreciation: string;
//   taxRevenue: string;
// }>;

export interface Case extends CaseEntity, HasCreatedBy {
  readonly owner?: string;
  readonly projectId?: string;
  readonly name?: string;
  readonly description?: string;
  readonly createdFrom?: string;
  readonly nodeType?: NodeType;
  // readonly economics?: Economics;
  readonly createdDate?: string;
  readonly updatedDate?: string;
  readonly copyStatus?: string;
  readonly wacc?: string;
  readonly colorScheme?: PerceptionMap;
}

export type NodeType = 'single' | 'multiple';
