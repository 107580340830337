import { DialogService } from 'prosumer-app/libs/eyes-core';
import { BaseDialogComponent, contains } from 'prosumer-app/libs/eyes-shared';
import { getUpdatedStationVehicleAssoc } from 'prosumer-shared';
import { Observable } from 'rxjs';

import { TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Scenario, StationVehicleAssoc, VehiclesDispatch } from '../models';

interface FilterVehiclesDispatchReturn {
  remainVehicles: Array<VehiclesDispatch>;
  deletedVehiclesId: Array<string>;
}

@Injectable()
export class RouteCascaderService {
  constructor(
    private _dialog: DialogService,
    private _titleCase: TitleCasePipe,
    private _translate: TranslateService,
  ) {}

  // prettier-ignore
  generateAffectedMessage([vehicleDispatches, stationVehicleAssocs]: [
    Array<VehiclesDispatch>,
    Array<StationVehicleAssoc>
  ]) {

    // eslint-disable-next-line max-len
    const affectedVehiclesDisptach = vehicleDispatches.map((vehicle) => vehicle.vehicleName).reduce((prev, next) => prev + `<li>${next}</li>`, '');
    // eslint-disable-next-line max-len
    const affectedStationVehicleAssocs = (stationVehicleAssocs || []).length > 0 ? `${stationVehicleAssocs.length} Station/Vehicle Associations` : '';

    if (affectedVehiclesDisptach.length === 0 && affectedStationVehicleAssocs.length === 0) {
      return '';
    }

    const affectedMessage =
      `<div class="list-wrapper"><b>Affected Data</b>` +
      `${affectedVehiclesDisptach.length > 0 ? `<br>Vehicles Dispatch:<br>${affectedVehiclesDisptach}` : ''}` +
      `${affectedStationVehicleAssocs.length > 0 ? `<br>Station/Vehicle Association:<br>${affectedStationVehicleAssocs}` : ''}` +
      `</div>`;
    return affectedMessage;
  }

  generateMessage(
    base: string,
    deletedNames?: Array<string>,
    affectedMessage?: string,
  ) {
    let deletedStr = '';
    deletedNames.forEach(
      (name) => (deletedStr += `<li>${this._titleCase.transform(name)}</li>`),
    );

    return `${base}<div class="list-wrapper"><b>To Delete</b><ul>${deletedStr}</ul></div>${affectedMessage}`;
  }

  showDeletedDialog(
    deletedNames: Array<string>,
    affectedMessage?: string,
  ): Observable<any> {
    return this._dialog.openDialog(BaseDialogComponent, {
      title: this._translate.instant(
        'Scenario.dialog.selectionDeleted.mobility.title',
      ),
      message: this.generateMessage(
        this._translate.instant(
          'Scenario.dialog.selectionDeleted.mobility.message',
        ),
        deletedNames,
        affectedMessage,
      ),
      confirm: this._translate.instant('Generic.labels.yes'),
      close: this._translate.instant('Generic.labels.no'),
      width: 400,
    });
  }

  cascadeRouteDeletion(
    scenario: Scenario,
    deletedRouteIds: Array<string>,
  ): Scenario {
    if (!!!scenario) {
      return;
    }
    const vehiclesDispatch = ((scenario || {}).mobility || {}).vehicles || [];
    const stationVehicleAssoc =
      ((scenario || {}).mobility || {}).stationVehicleAssoc || [];
    const cascadedVehiclesDispatch = this.filterVehiclesDispatch(
      vehiclesDispatch,
      deletedRouteIds,
    );
    const cascadedStationVehicleAssoc = this.filterStationVehicleAssoc(
      stationVehicleAssoc,
      vehiclesDispatch,
      cascadedVehiclesDispatch.deletedVehiclesId,
    );

    return {
      ...scenario,
      mobility: {
        ...scenario.mobility,
        vehicles: cascadedVehiclesDispatch.remainVehicles,
        stationVehicleAssoc: cascadedStationVehicleAssoc,
      },
    };
  }

  filterVehiclesDispatch(
    vehicles: Array<VehiclesDispatch>,
    deletedRouteIds: Array<string>,
  ): FilterVehiclesDispatchReturn {
    if (!!!vehicles) {
      return {
        remainVehicles: vehicles,
        deletedVehiclesId: [],
      };
    }

    const deletedVehiclesId = [];
    const remainVehicles = vehicles.filter((vehicle) => {
      const ret = vehicle.routeIds.filter((routeId) =>
        contains(deletedRouteIds, routeId),
      );
      if (ret.length > 0) {
        deletedVehiclesId.push(vehicle.id);
      }
      return ret.length === 0;
    });

    return {
      remainVehicles,
      deletedVehiclesId,
    };
  }

  filterStationVehicleAssoc(
    stationVehicleAssocs: Array<StationVehicleAssoc>,
    currentVehiclesList: Array<VehiclesDispatch>,
    deletedVehicleIds: Array<string>,
  ) {
    if (
      !!!stationVehicleAssocs ||
      !!!currentVehiclesList ||
      !!!currentVehiclesList
    ) {
      return stationVehicleAssocs;
    }
    const remainingVehicles = currentVehiclesList.filter(
      (vehicle) => !contains(deletedVehicleIds, vehicle.id),
    );
    const updatedAssoc: StationVehicleAssoc[] = getUpdatedStationVehicleAssoc(
      stationVehicleAssocs,
      currentVehiclesList,
      remainingVehicles,
    );

    return updatedAssoc.filter(
      (assoc) =>
        assoc.vehicleNames.filter((vehicleId) =>
          contains(deletedVehicleIds, vehicleId),
        ).length === 0,
    );
  }
}
