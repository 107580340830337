import { Library } from './library.model';

export type LoadType = 'custom' | 'library';

// Deprecated
export interface BinaryData {
  readonly localId?: string;
  readonly location?: string;
  readonly data?: Array<string>;
  readonly forSaving?: boolean;
}

export interface Profile {
  readonly localId: string;
  // original localId of profile
  readonly originalLocalId?: string;
  readonly location?: string;
  readonly loadProfile?: Array<string>;
  readonly forSaving?: boolean;
  readonly loadType?: LoadType;
  readonly library?: Library | string;
  readonly yearlyLoad?: number | string;
  readonly startYear?: number;
  readonly endYear?: number;
  readonly loadError?: boolean;
  readonly isCustom?: boolean;
}

export interface BinaryDelete {
  readonly binToDelete?: Array<Profile>;
  readonly binToClone?: Array<BinaryDataToClone>;
}

export interface BinaryDataToClone extends Profile {
  readonly newLocalId?: string;
}

export const areBinariesIdentical = (
  previous: BinaryData,
  next: BinaryData, // TODO null;
) => (previous.data || []).join() === (next.data || []).join();

export interface ProfileBE {
  isCustom?: boolean;
  localId?: string;
  libraryId?: string;
  yearlyLoad?: string;
  year?: number;
  duration?: number;
}

export interface RouteProfileBE extends ProfileBE {
  readonly nodes?: Array<string>;
}

export interface ProfileGroupBE {
  readonly name?: string;
  readonly profiles: ProfileBE[];
}
