import { Connector, Node } from './base.model';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface NodeData extends Node {}

export interface LineData extends Connector {
  readonly energyVector: string;
}

export interface OverviewData {
  readonly nodes: Array<NodeData>;
  readonly lines: Array<LineData>;
}
