import { BehaviorSubject } from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable()
export class ManagedIndexedDataService<T = Record<string, string> | string> {
  data$ = new BehaviorSubject<Array<T>>([]);
  set data(value: Array<T> | undefined) {
    this.data$.next(value ?? []);
  }
  get data() {
    return this.data$.value;
  }

  add(datum: T) {
    if (typeof datum === 'string') {
      this.data = [...(this.data ?? []), datum];
    } else {
      this.data = [{ ...datum }, ...(this.data ?? [])];
    }
  }

  edit(index: number, datum: Partial<T>) {
    if (typeof datum === 'string') {
      this.data = [...(this.data?.filter((_, i) => i !== index) || []), datum];
    } else {
      this.data = [
        {
          ...(this.data?.[index] || {}),
          ...datum,
        } as T,
        ...(this.data?.filter((_, i) => i !== index) || []),
      ];
    }
  }

  delete(index: number) {
    this.data = [...(this.data?.filter((_, i) => i !== index) || [])];
  }

  clear() {
    this.data = [];
  }
}
