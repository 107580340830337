import { Utils } from 'prosumer-app/core';
import { contains } from 'prosumer-app/libs/eyes-shared';
import { getBinsToDeleteOnDelete } from 'prosumer-shared';
import {
  Converter,
  EnergyGridConnection,
  EnergyGridConnectionsEmissions,
  Equipment,
  Generator,
  Load,
  OperatingCost,
  Profile,
  ProfileRoutes,
  RenewableEnergy,
  Station,
  Storage,
  Vehicle,
} from '../models';

type ObjectWithBindata =
  | EnergyGridConnection
  | EnergyGridConnectionsEmissions
  | Load
  | Equipment
  | RenewableEnergy
  | Converter
  | Storage
  | Generator
  | Vehicle
  | Station;

export class BindataCascaderExt {
  static cascadeBinDeletion(
    cascadedObjs: Array<ObjectWithBindata>,
    currentObjs: Array<ObjectWithBindata>,
  ): Array<Profile> {
    return [].concat(
      ...currentObjs
        .filter((obj) => !contains(cascadedObjs, obj))
        .map((obj) => {
          let toDelete: Array<Profile | ProfileRoutes> = [];
          toDelete = toDelete.concat(
            Utils.resolveToEmptyArray(
              getBinsToDeleteOnDelete(undefined, obj.profiles),
            ),
          );
          toDelete = toDelete.concat(
            Utils.resolveToEmptyArray(
              (obj['operatingCostProfiles'] || []).map(
                (costProfile: OperatingCost) => costProfile.profiles,
              ),
            ),
          );
          return toDelete;
        }),
    );
  }
}
