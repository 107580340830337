import { Utils } from 'prosumer-app/core';
import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import {
  CommoditiesForm,
  EnergyGridConnection,
  EnergyGridConnectionsEmissions,
  EnergyGridLimit,
  Equipment,
  EquipmentCircuitInfo,
  EquipmentForm,
  EquipmentReserve,
  FrequencyControlForm,
  Load,
  LoadsForm,
  MarketReserve,
  Metering,
  MobilityForm,
  Netting,
  NettingForm,
  RegulationsForm,
  Scenario,
  ShortCircuit,
  SpinningReserve,
  StationVehicleAssoc,
  TaxAndSubsidies,
  VehiclesDispatch,
} from '../models';
import { CascadeConfirmationDialog } from './cascade-confirmation.dialog';
import { CascadeMessageFormatter } from './cascade-message.formatter';
import {
  Affected,
  AffectedCategory,
  DeletedEntityAffector,
  HasScenarioVariation,
  VariationCascader,
} from './scenario-variation-cascader.base';
import { VariationCascaderExt } from './variation-cascader.ext';
import { BindataCascaderExt } from './bindata-cascader.ext';

@Injectable()
export class ScenarioVariationCascader
  implements VariationCascader, DeletedEntityAffector
{
  constructor(private readonly dialog: CascadeConfirmationDialog) {}

  showDeletedDialog(deleted: string[], message: string): Observable<unknown> {
    return this.dialog.open(deleted, message);
  }

  generateAffectedMessage(param: [Scenario, string[]]): string {
    return CascadeMessageFormatter.format(this.collateAffected(...param));
  }

  private collateAffected(scenario: Scenario, deleted: string[]): Affected[] {
    return [
      ...this.touchEquipments(scenario, deleted),
      ...this.touchLoads(scenario, deleted),
      ...this.touchEGCs(scenario, deleted),
      ...this.touchEGCEmissions(
        scenario,
        this.affectedEGCIds(scenario, deleted),
      ),
      ...this.touchLimits(scenario, this.affectedEGCIds(scenario, deleted)),
      ...this.touchVehiclesDispatch(
        scenario,
        this.affectedEquipIds(scenario, deleted),
      ),
      ...this.touchStationVehicleAssoc(
        scenario,
        this.affectedEquipIds(scenario, deleted),
      ),
      ...this.touchCircuitEquipments(
        scenario,
        this.affectedEquipIds(scenario, deleted),
      ),
      ...this.touchMarketReserves(
        scenario,
        this.affectedEGCIds(scenario, deleted),
      ),
      ...this.touchEquipmentReserves(
        scenario,
        this.affectedEquipIds(scenario, deleted),
      ),
      ...this.touchNetting(scenario, this.affectedEquipIds(scenario, deleted)),
      ...this.touchTaxAndSubsidies(
        scenario,
        this.affectedNettingIds(scenario, deleted),
      ),
      ...this.touchMeterings(
        scenario,
        this.affectedEquipIds(scenario, deleted),
      ),
    ];
  }

  touchEquipments(scenario: Scenario, deleted: string[]): Affected[] {
    return this.getAffectedEquipments(scenario, deleted).map((equipment) =>
      this.equipmentToAffected(equipment),
    );
  }

  getAffectedEquipments(scenario: Scenario, deleted: string[]): Equipment[] {
    return VariationCascaderExt.resolveEquipments(scenario).filter(
      (equipment) => deleted.includes(equipment.scenarioVariation),
    );
  }

  private equipmentToAffected(equipment: Equipment): Affected {
    return { name: equipment.name, category: AffectedCategory.equip };
  }

  touchLoads(scenario: Scenario, deleted: string[]): Affected[] {
    return this.getAffectedLoads(scenario, deleted).map((load) =>
      this.loadToAffected(load),
    );
  }

  getAffectedLoads(scenario: Scenario, deleted: string[]): Load[] {
    return VariationCascaderExt.resolveLoads(scenario).filter((load) =>
      deleted.includes(load.scenarioVariation),
    );
  }

  private loadToAffected(load: Load): Affected {
    return { name: load.name, category: AffectedCategory.loads };
  }

  touchEGCs(scenario: Scenario, deleted: string[]): Affected[] {
    return this.getAffectedEGCs(scenario, deleted).map((egc) =>
      this.egcToAffected(egc),
    );
  }

  getAffectedEGCs(
    scenario: Scenario,
    deleted: string[],
  ): EnergyGridConnection[] {
    return VariationCascaderExt.resolveGrids(scenario).filter((egc) =>
      deleted.includes(egc.scenarioVariation),
    );
  }

  private egcToAffected(egc: EnergyGridConnection): Affected {
    return { name: egc.marketName, category: AffectedCategory.commEGCs };
  }

  touchEGCEmissions(scenario: Scenario, egcIDs: string[]): Affected[] {
    return this.getAffectedEGCEmissions(scenario, egcIDs).map((egcEmission) =>
      this.egcEmissionsToAffected(egcEmission, scenario),
    );
  }

  getAffectedEGCEmissions(
    scenario: Scenario,
    egcIDs: string[],
  ): EnergyGridConnectionsEmissions[] {
    return VariationCascaderExt.resolveConnections(scenario).filter(
      (egcEmissions) => egcIDs.includes(egcEmissions.marketName),
    );
  }

  private egcEmissionsToAffected(
    egcEmission: EnergyGridConnectionsEmissions,
    scenario: Scenario,
  ): Affected {
    return {
      category: AffectedCategory.commEGCsEmissions,
      name: `${this.findMarketName(
        egcEmission.marketName,
        scenario,
      )} (${this.findNodeName(egcEmission.node, scenario)}, ${
        egcEmission.scope
      })`,
    };
  }

  private findMarketName(marketId: string, scenario: Scenario): string {
    return VariationCascaderExt.resolveGrids(scenario).find(
      (grid) => grid.id === marketId,
    ).marketName;
  }

  touchLimits(scenario: Scenario, egcIDs: string[]): Affected[] {
    return this.getAffectedLimits(scenario, egcIDs).map((limit) =>
      this.limitsToAffected(limit, scenario),
    );
  }

  getAffectedLimits(scenario: Scenario, egcIDs: string[]): EnergyGridLimit[] {
    return VariationCascaderExt.resolveLimits(scenario).filter((limit) =>
      egcIDs.includes(limit.market),
    );
  }

  private limitsToAffected(
    limit: EnergyGridLimit,
    scenario: Scenario,
  ): Affected {
    return {
      category: AffectedCategory.commLimits,
      name: `${this.findMarketName(
        limit.market,
        scenario,
      )} (${this.findLimitNodeNames(limit, scenario)})`,
    };
  }

  private findLimitNodeNames(
    limit: EnergyGridLimit,
    scenario: Scenario,
  ): string {
    return limit.nodes
      .map((node) => this.findNodeName(node, scenario))
      .join(', ');
  }

  touchVehiclesDispatch(
    scenario: Scenario,
    equipmentIds: string[],
  ): Affected[] {
    return this.getAffectedVehiclesDispatch(scenario, equipmentIds).map(
      (dispatch) => this.vehicleDispatchToAffected(dispatch),
    );
  }

  getAffectedVehiclesDispatch(
    scenario: Scenario,
    equipmentIds: string[],
  ): VehiclesDispatch[] {
    return VariationCascaderExt.resolveVehiclesDispatch(scenario).filter(
      (dispatch) => equipmentIds.includes(dispatch.vehicleId),
    );
  }

  private vehicleDispatchToAffected(dispatch: VehiclesDispatch): Affected {
    return {
      category: AffectedCategory.vehiclesDispatch,
      name: dispatch.vehicleName,
    };
  }

  touchStationVehicleAssoc(
    scenario: Scenario,
    equipmentIds: string[],
  ): Affected[] {
    const affectedStationVehicleAssoc = this.getAffectedStationVehicleAssoc(
      scenario,
      equipmentIds,
    );
    return affectedStationVehicleAssoc.length
      ? [this.stationVehicleAssocToAffected(affectedStationVehicleAssoc.length)]
      : [];
  }

  getAffectedStationVehicleAssoc(
    scenario: Scenario,
    equipmentIds: string[],
  ): StationVehicleAssoc[] {
    return VariationCascaderExt.resolveStationVehicleAssoc(scenario).filter(
      (association) =>
        [association.vehicleId, association.stationId].some((id) =>
          equipmentIds.includes(id),
        ),
    );
  }

  private stationVehicleAssocToAffected(
    numberOfAffectedAssoc: number,
  ): Affected {
    return {
      category: AffectedCategory.stationVehicleAssoc,
      name: `${numberOfAffectedAssoc} Station/Vehicle Associations`,
    };
  }

  touchCircuitEquipments(
    scenario: Scenario,
    equipmentIds: string[],
  ): Affected[] {
    return this.getAffectedCircuitEquipments(scenario, equipmentIds).map(
      (reserve) => this.circuitEquipmentToAffected(reserve, scenario),
    );
  }

  getAffectedCircuitEquipments(
    scenario: Scenario,
    equipmentIds: string[],
  ): EquipmentCircuitInfo[] {
    return VariationCascaderExt.resolveCircuitEquips(scenario).filter(
      (circuitEquipment) => equipmentIds.includes(circuitEquipment.equipmentId),
    );
  }

  private circuitEquipmentToAffected(
    circuitEquipment: EquipmentCircuitInfo,
    scenario: Scenario,
  ): Affected {
    return {
      category: AffectedCategory.circuitEquips,
      name: this.findEquipmentName(circuitEquipment.equipmentId, scenario),
    };
  }

  touchMarketReserves(scenario: Scenario, egcIDs: string[]): Affected[] {
    return this.getAffectedReserves(scenario, egcIDs).map((reserve) =>
      this.marketReserveToAffected(reserve, scenario),
    );
  }

  getAffectedReserves(scenario: Scenario, egcIDs: string[]): MarketReserve[] {
    return VariationCascaderExt.resolveMarketReserves(scenario).filter(
      (reserve) => egcIDs.includes(reserve.energyGridConnectionId),
    );
  }

  private marketReserveToAffected(
    reserve: MarketReserve,
    scenario: Scenario,
  ): Affected {
    return {
      category: AffectedCategory.reserveMarket,
      name: this.findMarketReserveName(reserve, scenario),
    };
  }

  private findMarketReserveName(
    reserve: MarketReserve,
    scenario: Scenario,
  ): string {
    return VariationCascaderExt.resolveGrids(scenario).find(
      (grid) => grid.id === reserve.energyGridConnectionId,
    ).marketName;
  }

  touchEquipmentReserves(
    scenario: Scenario,
    equipmentIds: string[],
  ): Affected[] {
    return this.getAffectedEquipmentReserves(scenario, equipmentIds).map(
      (reserve) => this.equipmentReserveToAffected(reserve, scenario),
    );
  }

  getAffectedEquipmentReserves(
    scenario: Scenario,
    equipmentIds: string[],
  ): EquipmentReserve[] {
    return VariationCascaderExt.resolveMarketEquips(scenario).filter(
      (reserve) => equipmentIds.includes(reserve.equipmentId),
    );
  }

  private equipmentReserveToAffected(
    reserve: EquipmentReserve,
    scenario: Scenario,
  ): Affected {
    return {
      category: AffectedCategory.reserveEquips,
      name: this.findEquipmentName(reserve.equipmentId, scenario),
    };
  }

  touchNetting(scenario: Scenario, equipmentIds: string[]): Affected[] {
    return this.getAffectedNetting(scenario, equipmentIds).map((netting) =>
      this.nettingToAffected(netting),
    );
  }

  getAffectedNetting(scenario: Scenario, equipmentIds: string[]): Netting[] {
    return VariationCascaderExt.resolveNettings(scenario).filter(
      (netting) =>
        Utils.resolveToEmptyArray(netting.equipments).filter(
          (nettingEquipment) => !equipmentIds.includes(nettingEquipment),
        ).length === 0,
    );
  }

  private nettingToAffected(netting: Netting): Affected {
    return {
      category: AffectedCategory.netting,
      name: netting.name,
    };
  }

  touchTaxAndSubsidies(scenario: Scenario, nettingIds: string[]): Affected[] {
    return this.getAffectedTaxAndSubsidies(scenario, nettingIds).map(
      (taxAndSubsidy) =>
        this.taxAndSubsidiesToAffected(taxAndSubsidy, scenario),
    );
  }

  getAffectedTaxAndSubsidies(
    scenario: Scenario,
    nettingIds: string[],
  ): TaxAndSubsidies[] {
    return VariationCascaderExt.resolveTaxAndSubsidies(scenario).filter(
      (taxAndSubsidy) => nettingIds.includes(taxAndSubsidy.netting),
    );
  }

  private taxAndSubsidiesToAffected(
    taxAndSubsidy: TaxAndSubsidies,
    scenario: Scenario,
  ): Affected {
    return {
      category: AffectedCategory.taxAndSubsidies,
      name: `${this.findTaxAndSubsidyNettingName(
        taxAndSubsidy.netting,
        scenario,
      )} (${this.findTaxAndSubsidyNodeNames(taxAndSubsidy, scenario)})`,
    };
  }

  private findTaxAndSubsidyNettingName(
    nettingId: string,
    scenario: Scenario,
  ): string {
    return VariationCascaderExt.resolveNettings(scenario).find(
      (netting) => netting.id === nettingId,
    ).name;
  }

  private findTaxAndSubsidyNodeNames(
    taxAndSubsidy: TaxAndSubsidies,
    scenario: Scenario,
  ): string {
    return taxAndSubsidy.nodes
      .map((node) => this.findNodeName(node, scenario))
      .join(', ');
  }

  touchMeterings(scenario: Scenario, equipmentIds: string[]): Affected[] {
    return this.getAffectedMeterings(scenario, equipmentIds).map((metering) =>
      this.meteringToAffected(metering, scenario),
    );
  }

  getAffectedMeterings(scenario: Scenario, equipmentIds: string[]): Metering[] {
    return VariationCascaderExt.resolveMeterings(scenario).filter((metering) =>
      equipmentIds.includes(metering.technologyId),
    );
  }

  private meteringToAffected(metering: Metering, scenario: Scenario): Affected {
    return {
      category: AffectedCategory.regulation,
      name: this.findEquipmentName(metering.technologyId, scenario),
    };
  }

  cascadeScenarioVariation(scenario: Scenario, deleted: string[]): Scenario {
    return {
      ...scenario,
      equipments: this.cascadeToEquipments(scenario, deleted),
      loads: this.cascadeToLoads(scenario, deleted),
      commodities: this.cascadeToCommodities(scenario, deleted),
      mobility: this.cascadeToMobility(scenario, deleted),
      frequencyControl: this.cascadeToFreqControl(scenario, deleted),
      netting: this.cascadeToNetting(scenario, deleted),
      regulations: this.cascadeToRegulations(scenario, deleted),
    };
  }

  private cascadeToEquipments(
    scenario: Scenario,
    deleted: string[],
  ): EquipmentForm {
    return {
      ...scenario.equipments,
      equipments: this.filterEquipments(scenario, deleted),
      binToDelete: BindataCascaderExt.cascadeBinDeletion(
        this.filterEquipments(scenario, deleted),
        VariationCascaderExt.resolveEquipments(scenario),
      ),
    };
  }

  private filterEquipments(scenario: Scenario, deleted: string[]): Equipment[] {
    return this.filterHasScenarioVars(
      VariationCascaderExt.resolveEquipments(scenario),
      deleted,
    );
  }

  private cascadeToLoads(scenario: Scenario, deleted: string[]): LoadsForm {
    return {
      ...scenario.loads,
      loads: this.filterLoads(scenario, deleted),
      binToDelete: BindataCascaderExt.cascadeBinDeletion(
        this.filterLoads(scenario, deleted),
        VariationCascaderExt.resolveLoads(scenario),
      ),
    };
  }

  private filterLoads(scenario: Scenario, deleted: string[]): Equipment[] {
    return this.filterHasScenarioVars(
      VariationCascaderExt.resolveLoads(scenario),
      deleted,
    );
  }

  private cascadeToCommodities(
    scenario: Scenario,
    deleted: string[],
  ): CommoditiesForm {
    const tariffToDelete = BindataCascaderExt.cascadeBinDeletion(
      this.filterGrids(scenario, deleted),
      VariationCascaderExt.resolveGrids(scenario),
    );

    const emissionToDelete = BindataCascaderExt.cascadeBinDeletion(
      this.filterEmissions(scenario, deleted),
      VariationCascaderExt.resolveConnections(scenario),
    );

    return {
      ...scenario.commodities,
      grids: this.filterGrids(scenario, deleted),
      connectionsEmissions: this.filterEmissions(scenario, deleted),
      limits: this.filterLimits(scenario, deleted),
      binToDelete: [...tariffToDelete, ...emissionToDelete],
    };
  }

  private filterGrids(
    scenario: Scenario,
    deleted: string[],
  ): EnergyGridConnection[] {
    return this.filterHasScenarioVars(
      VariationCascaderExt.resolveGrids(scenario),
      deleted,
    );
  }

  private filterEmissions(
    scenario: Scenario,
    deleted: string[],
  ): EnergyGridLimit[] {
    return VariationCascaderExt.resolveConnections(scenario).filter(
      (limit) => !this.isEgcEmissionAffected(limit, scenario, deleted),
    );
  }

  private isEgcEmissionAffected(
    egcEmission: EnergyGridConnection,
    scenario: Scenario,
    deleted: string[],
  ): boolean {
    return this.affectedEGCIds(scenario, deleted).includes(
      egcEmission.marketName,
    );
  }

  private filterLimits(
    scenario: Scenario,
    deleted: string[],
  ): EnergyGridLimit[] {
    return VariationCascaderExt.resolveLimits(scenario).filter(
      (limit) => !this.isMarketLimitAffected(limit, scenario, deleted),
    );
  }

  private isMarketLimitAffected(
    limit: EnergyGridLimit,
    scenario: Scenario,
    deleted: string[],
  ): boolean {
    return this.affectedEGCIds(scenario, deleted).includes(limit.market);
  }

  private cascadeToMobility(
    scenario: Scenario,
    deleted: string[],
  ): MobilityForm {
    return {
      ...scenario.mobility,
      vehicles: this.filterVehiclesDispatch(scenario, deleted),
      stationVehicleAssoc: this.filterStationVehicleAssoc(scenario, deleted),
    };
  }

  private filterVehiclesDispatch(
    scenario: Scenario,
    deleted: string[],
  ): VehiclesDispatch[] {
    return VariationCascaderExt.resolveVehiclesDispatch(scenario).filter(
      (vehicleDispatch) =>
        !this.isVehicleDispatchAffected(vehicleDispatch, scenario, deleted),
    );
  }

  private isVehicleDispatchAffected(
    vehicleDispatch: VehiclesDispatch,
    scenario: Scenario,
    deleted: string[],
  ): boolean {
    return this.affectedEquipIds(scenario, deleted).includes(
      vehicleDispatch.vehicleId,
    );
  }

  private filterStationVehicleAssoc(
    scenario: Scenario,
    deleted: string[],
  ): StationVehicleAssoc[] {
    return VariationCascaderExt.resolveStationVehicleAssoc(scenario).filter(
      (association) =>
        !this.isStationVehicleAssocAffected(association, scenario, deleted),
    );
  }

  private isStationVehicleAssocAffected(
    association: StationVehicleAssoc,
    scenario: Scenario,
    deleted: string[],
  ): boolean {
    return [association.vehicleId, association.stationId].some((id) =>
      this.affectedEquipIds(scenario, deleted).includes(id),
    );
  }

  private cascadeToFreqControl(
    scenario: Scenario,
    deleted: string[],
  ): FrequencyControlForm {
    return {
      ...scenario.frequencyControl,
      spinningReserve: this.cascadeToSpinReserve(scenario, deleted),
      shortCircuit: this.cascadeToCircuit(scenario, deleted),
    };
  }

  private cascadeToSpinReserve(
    scenario: Scenario,
    deleted: string[],
  ): SpinningReserve {
    return {
      ...VariationCascaderExt.resolveSpinReserve(scenario),
      markets: this.filterMarketReserves(scenario, deleted),
      equipments: this.filterMarketEquips(scenario, deleted),
    };
  }

  private filterMarketReserves(
    scenario: Scenario,
    deleted: string[],
  ): MarketReserve[] {
    return VariationCascaderExt.resolveMarketReserves(scenario).filter(
      (reserve) => !this.isMarketReserveAffected(reserve, scenario, deleted),
    );
  }

  private isMarketReserveAffected(
    reserve: MarketReserve,
    scenario: Scenario,
    deleted: string[],
  ): boolean {
    return this.affectedEGCIds(scenario, deleted).includes(
      reserve.energyGridConnectionId,
    );
  }

  private filterMarketEquips(
    scenario: Scenario,
    deleted: string[],
  ): EquipmentReserve[] {
    return VariationCascaderExt.resolveMarketEquips(scenario).filter(
      (reserve) => !this.isEquipReserveAffected(reserve, scenario, deleted),
    );
  }

  private isEquipReserveAffected(
    reserve: EquipmentReserve,
    scenario: Scenario,
    deleted: string[],
  ): boolean {
    return this.affectedEquipIds(scenario, deleted).includes(
      reserve.equipmentId,
    );
  }

  private cascadeToCircuit(
    scenario: Scenario,
    deleted: string[],
  ): ShortCircuit {
    return {
      ...VariationCascaderExt.resolveCircuit(scenario),
      equipments: this.filterCircuitEquips(scenario, deleted),
    };
  }

  private filterCircuitEquips(
    scenario: Scenario,
    deleted: string[],
  ): EquipmentCircuitInfo[] {
    return VariationCascaderExt.resolveCircuitEquips(scenario).filter(
      (equip) => !this.isCircuitEquipAffected(equip, scenario, deleted),
    );
  }

  private isCircuitEquipAffected(
    equip: EquipmentCircuitInfo,
    scenario: Scenario,
    deleted: string[],
  ): boolean {
    return this.affectedEquipIds(scenario, deleted).includes(equip.equipmentId);
  }

  private cascadeToNetting(scenario: Scenario, deleted: string[]): NettingForm {
    const netting = this.filterNettings(scenario, deleted);

    return {
      ...scenario.netting,
      netting,
      taxAndSubsidies: this.filterTaxAndSubsidies(scenario, netting),
    };
  }

  private filterNettings(scenario: Scenario, deleted: string[]): Netting[] {
    return VariationCascaderExt.resolveNettings(scenario)
      .map((netting) => ({
        ...netting,
        equipments: this.filterNettingEquipments(netting, scenario, deleted),
        loads: this.filterNettingLoads(netting, scenario, deleted),
      }))
      .filter((netting) => netting.equipments.length !== 0);
  }

  private filterNettingEquipments(
    netting: Netting,
    scenario: Scenario,
    deleted: string[],
  ): string[] {
    return netting.equipments.filter(
      (equipment) =>
        !this.affectedEquipIds(scenario, deleted).includes(equipment),
    );
  }

  private filterNettingLoads(
    netting: Netting,
    scenario: Scenario,
    deleted: string[],
  ): string[] {
    return netting.loads.filter(
      (load) => !this.affectedLoadIds(scenario, deleted).includes(load),
    );
  }

  private filterTaxAndSubsidies(
    scenario: Scenario,
    nettings: Netting[],
  ): TaxAndSubsidies[] {
    return VariationCascaderExt.resolveTaxAndSubsidies(scenario).filter(
      (taxAndSubsidy) =>
        this.shouldRetainTaxAndSubsidies(taxAndSubsidy, nettings),
    );
  }

  private shouldRetainTaxAndSubsidies(
    taxAndSubsidy: TaxAndSubsidies,
    nettings: Netting[],
  ): boolean {
    return nettings
      .map((netting) => netting.id)
      .includes(taxAndSubsidy.netting);
  }

  private cascadeToRegulations(
    scenario: Scenario,
    deleted: string[],
  ): RegulationsForm {
    return {
      ...scenario.regulations,
      meterings: this.filterMeterings(scenario, deleted),
    };
  }

  private filterMeterings(scenario: Scenario, deleted: string[]): Metering[] {
    return VariationCascaderExt.resolveMeterings(scenario).filter(
      (metering) => !this.isMeteringAffected(metering, scenario, deleted),
    );
  }

  private isMeteringAffected(
    metering: Metering,
    scenario: Scenario,
    deleted: string[],
  ): boolean {
    return this.affectedEquipIds(scenario, deleted).includes(
      metering.technologyId,
    );
  }

  private affectedEGCIds(scenario: Scenario, deleted: string[]): string[] {
    return this.getAffectedEGCs(scenario, deleted).map((egc) => egc.id);
  }

  private affectedEquipIds(scenario: Scenario, deleted: string[]): string[] {
    return this.getAffectedEquipments(scenario, deleted).map(
      (equipment) => equipment.id,
    );
  }

  private affectedLoadIds(scenario: Scenario, deleted: string[]): string[] {
    return this.getAffectedLoads(scenario, deleted).map((load) => load.id);
  }

  private affectedNettingIds(scenario: Scenario, deleted: string[]): string[] {
    return this.getAffectedNetting(
      scenario,
      this.affectedEquipIds(scenario, deleted),
    ).map((netting) => netting.id);
  }

  private findNodeName(nodeId: string, scenario: Scenario): string {
    if (nodeId === 'ALL') {
      return nodeId;
    }
    return VariationCascaderExt.resolveNodes(scenario).find(
      (node) => node.value === nodeId,
    ).name;
  }

  private findEquipmentName(equipmentId: string, scenario: Scenario): string {
    return VariationCascaderExt.resolveEquipments(scenario).find(
      (equipment) => equipment.id === equipmentId,
    ).name;
  }

  private filterHasScenarioVars(
    vars: HasScenarioVariation[],
    deleted: string[],
  ): HasScenarioVariation[] {
    return vars.filter((vrtn) => !deleted.includes(vrtn.scenarioVariation));
  }
}
