import { Connector, Node } from './base.model';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Asset extends Node {
  fluid?: string;
  outputFluid?: string;
  node: string;
}

export interface AssetLink extends Connector {
  fluid: string;
}

export interface NodeViewData {
  readonly assets: Array<Asset>;
  readonly assetLinks: Array<AssetLink>;
}
