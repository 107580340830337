import { Position } from '../../models/index';

export type FormFieldErrorMessageMap = Readonly<{
  [module: string]: { [errorKey: string]: string };
}>;

export type FormFieldOption<T> = Readonly<{
  name: string;
  value: T;
  tooltip?: string;
  tooltipPosition?: Position;
}>;
