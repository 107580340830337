import { DialogData, FormFieldOption } from 'prosumer-app/libs/eyes-shared';
import { Fuel } from 'prosumer-scenario';
import { YearlyValues } from 'prosumer-shared';
import { Observable } from 'rxjs';

export type FuelsFormDialogMode = 'add' | 'edit';

export interface FuelsFormDialogData extends DialogData {
  mode: FuelsFormDialogMode;
  id?: string;
  fuel: string;
  nodes: Array<string>;
  fuelCost: YearlyValues;
  co2Rate: YearlyValues;
  co2Scope3EmissionFactor: YearlyValues;
  energyVectorOptions: Array<FormFieldOption<any>>;
  nodeOptions: Array<FormFieldOption<any>>;
  existingFuels$: Observable<Array<Fuel>>;
  fuelData?: Fuel;
  isMultiNode?: boolean;
  startYear?: number;
  endYear?: number;
  isViewOnly?: boolean;
}
