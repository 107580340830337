import { BinaryData, BinaryDelete, Profile } from './binary-data.model';

export interface ProfileRoutes extends Profile {
  readonly nodes?: Array<string>;
}

export interface Routes extends BinaryData {
  readonly name: string;
  readonly id: string;
  readonly profileRoutes: Array<ProfileRoutes>;
  readonly profiles?: Array<ProfileRoutes>;
}

export interface MobilityRoutes extends BinaryDelete {
  readonly routes?: Array<Routes>;
}

export interface VehiclesDispatch {
  readonly id: string;
  readonly vehicleName: string;
  readonly vehicleId: string;
  readonly routeIds: Array<string>;
  readonly startYear: number;
  readonly endYear: number;
  readonly numberOfVehicles: number;
  readonly v2g: boolean;
}

export interface StationVehicleAssoc {
  readonly id: string;
  readonly vehicleId: string;
  readonly stationId: string;
  readonly stationNodes: Array<string>;
  readonly vehicleNames: Array<string>;
  readonly vehicleIds?: Array<string>;
}

export interface MobilityForm {
  readonly routes?: MobilityRoutes;
  readonly vehicles?: Array<VehiclesDispatch>;
  readonly stationVehicleAssoc?: Array<StationVehicleAssoc>;
}
