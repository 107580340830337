import { Metering } from 'prosumer-app/+scenario/models';
import { DetailEntity } from '../scenario-detail';
import { YearValues } from '../scenario-generic';

export type Regulation = Metering & DetailEntity;
type CommonRegulation = Pick<Regulation, 'id' | 'technologyId' | 'netting'>;

export type RegulationBE = CommonRegulation &
  Readonly<{
    autoConsumptionTax: YearValues;
    generationTariff: YearValues;
    feedInTariff: YearValues;
    feedInPremium: YearValues;
  }>;

export type RegulationBERsp = CommonRegulation &
  Readonly<{
    autoConsumptionTax: string;
    generationTariff: string;
    feedInTariff: string;
    feedInPremium: string;
  }>;
