import { differenceInDays, format, isSameDay } from 'date-fns';

import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { TitleCasePipe } from '@angular/common';
import {
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
} from '@angular/forms';

function generateRandomNumber(maxValue: number = 100): number {
  return Math.floor(Math.random() * maxValue);
}

export const isErrorState = (
  control: FormControl | null | undefined,
  form: FormGroupDirective | NgForm | null | undefined,
): boolean => {
  const isSubmitted = form && form.submitted;
  return !!(
    control &&
    control.invalid &&
    (control.dirty || control.touched || isSubmitted)
  );
};

function toNumber(value: unknown): number {
  return Number(value) || 0;
}

function toPercentage(value: unknown): number {
  return (Number(value) || 0) * 100;
}

function toTitleCase<T extends string>(str: string): T {
  if (typeof str === 'string') {
    const pipe = new TitleCasePipe();
    return pipe.transform(str) as T;
  }
  return '' as T;
}

function roundToOneDecimal(num: number): number {
  return Math.round(num * 10) / 10;
}

function roundToTwoDecimals(num: number): number {
  return Math.round(num * 100) / 100;
}

function roundToNearestOne(num: number): number {
  return Math.round(num);
}

function resolveObject<T>(data: T): T {
  return data || ({} as T);
}

function resolveList<T>(data: T[] | undefined): T[] {
  return data || [];
}

function toDate(dateString: string): Date | null {
  if (coerceBooleanProperty(dateString)) {
    return new Date(dateString);
  }
  return null;
}

function getCurrentYear(utc: boolean = true): number {
  return utc
    ? getCurrentDate().getUTCFullYear()
    : getCurrentDate().getFullYear();
}

function getCurrentDate(): Date {
  return new Date();
}

function computeDateDurationInDays(start: Date, end: Date): number {
  if (isSameDay(end, start)) return 1;
  const days = differenceInDays(end, start);
  return days >= 0 ? days + 1 : 0;
}

function formatDate(
  date: Date | null | undefined,
  dateFormat: string = 'yyyy-MM-dd',
): string {
  if (!date) return '';
  return format(date, dateFormat);
}

function formatNumber(value: number, locales?: string | string[] | undefined) {
  return new Intl.NumberFormat(locales, {}).format(value);
}

function averageListOfNumbers(
  list: Array<number | null>,
  defaultReturn: number = 0,
): number {
  if (!list?.length) return defaultReturn;
  const divisor = list?.length ?? 1;
  return (
    <number>list?.reduce((a: number, b) => a + <number>b, 0) / divisor ??
    defaultReturn
  );
}

/**
 * Reusable mixin applicator function to achieve multiple inheritance.
 *
 * See documentation for more information: https://www.typescriptlang.org/docs/handbook/mixins.html
 *
 * Use sparingly and with care; there are cons when using mixins and
 * in our case, mixins are used only to avoid bloating of codes.
 *
 * See constraints: https://www.typescriptlang.org/docs/handbook/mixins.html#constraints
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function applyMixins(derivedCtor: any, constructors: any[]) {
  constructors.forEach((baseCtor) => {
    Object.getOwnPropertyNames(baseCtor.prototype).forEach((name) => {
      Object.defineProperty(
        derivedCtor.prototype,
        name,
        Object.getOwnPropertyDescriptor(baseCtor.prototype, name) ||
          Object.create(null),
      );
    });
  });
}

function isStrictNumber(
  value: string | number | null | undefined,
): value is NonNullable<typeof value> | number {
  return typeof value === 'number';
}

function getControlFromFormGroup<T>(
  key: string,
  formGroup: FormGroup,
): FormControl<T> {
  return formGroup.get(key) as FormControl<T>;
}

export const OculusUtils = {
  toNumber,
  toPercentage,
  toTitleCase,
  toDate,
  getCurrentYear,
  getCurrentDate,
  resolveObject,
  resolveList,
  computeDateDurationInDays,
  formatDate,
  formatNumber,
  generateRandomNumber,
  applyMixins,
  averageListOfNumbers,
  isStrictNumber,
  roundToOneDecimal,
  roundToTwoDecimals,
  roundToNearestOne,
  getControlFromFormGroup,
  isErrorState,
} as const;
