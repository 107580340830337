import {
  EnergyGridConnection,
  EnergyGridLimit,
} from 'prosumer-app/+scenario/models';
import { DialogData } from 'prosumer-app/libs/eyes-shared';
import { Observable } from 'rxjs';

export type EnergyGridLimitDialogMode = 'add' | 'edit' | 'duplicate';

export interface EnergyGridLimitDialogData extends DialogData {
  mode: EnergyGridLimitDialogMode;
  existingEnergyGridConnections$: Observable<Array<EnergyGridConnection>>;
  existingEnergyGridLimits$: Observable<Array<EnergyGridLimit>>;
  marketOptions: Array<{ name: string; value: string }>;
  nodeOptions: Array<{ name: string; value: string }>;
  startYear?: number;
  endYear?: number;
  isMultiNode?: boolean;
  editData?: EnergyGridLimit;
  isViewOnly?: boolean;
}
