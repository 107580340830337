export enum EngieColor {
  /* Fluid 5 */

  // main
  BlueBolt = '#007acd',

  // functional colors
  Danger = '#db3835',
  Warning = '#ff8c47',
  Success = '#418448',

  // additional colors
  PurpleLavender = '#6d4797',
  FrenchPink = '#f1468d',
  SpaceUltramarine = '#17255f',
  TealGreen = '#0a9994',
  SaffronYellow = '#f2c665',

  // brand
  BlueLogo = '#00aaff',

  // gbu colors
  EnergySolutions = '#e18554',
  Network = '#278cbc',
  ThermalSupply = '#4bb0b9',
  Renewable = '#67ae6e',

  // data visualization colors
  LightYellow = '#ffeaa1',
  LightOrange = '#ffbf8e',
  LightRed = '#ffbdb0',
  LightPink = '#ffb8d6',
  LightPurple = '#d6c6e9',
  LightBlue = '#b6cdf8',
  LightUltramarine = '#c4cbe5',
  LightTeal = '#88dad8',
  LightGreen = '#a2daa3',
  DarkYellow = '#a4853e',
  DarkOrange = '#bd5a21',
  DarkRed = '#aa2424',
  DarkPink = '#81475f',
  DarkPurple = '#4d3a62',
  DarkBlue = '#005aa0',
  DarkUltramarine = '#44549b',
  DarkTeal = '#0a6361',
  DarkGreen = '#316336',

  /* Fluid 2 */
  BlueCorporate = '#009de9',
  Red = '#cc0033',
  GreenJava = '#6ed2b1',
  Purple = '#1f106d',
  OrangeCrusta = '#f78a31',
  YellowBrightSun = '#fbdb3c',
  BlueRhino = '#2b485c',
  Cerise = '#e62b87',
  Green = '#009934',
  VioletEggplant = '#910f7d',
  BlueAllports = '#0b74c0',
  RedMandy = '#e74a50',
  GreenFun = '#69af23',
  Orange = '#e07602',
  Yellow = '#faca08',
  Violet = '#552382',
  BlueBigstone = '#0f1e29',
  GreenPine = '#007873',
  BlueVenice = '#005288',
  GreenRiogrande = '#becd00',
}
