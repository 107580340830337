import { NavigationExtras, Params } from '@angular/router';
import { RouterReducerState } from '@ngrx/router-store';

import { Breadcrumb, Feature } from 'prosumer-app/libs/eyes-shared';

export interface RouterData {
  path: any[];
  query?: Record<string, unknown>;
  extras?: NavigationExtras;
}

export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
  data: Feature;
  breadcrumbs: Array<Breadcrumb>;
}

export interface RouterStateExtended
  extends RouterReducerState<RouterStateUrl> {
  activeRoute: RouterStateUrl;
}
