import { EventEmitter } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

export type ButtonBehavior = 'submit' | 'reset' | 'button';

export interface Button {
  color?: ThemePalette;
  disabled?: () => boolean;
  label?: string;
  link?: string;
  behavior?: ButtonBehavior;
  eyesClick?: EventEmitter<any>;
  showPredicate?: () => boolean;
  iconPrefix?: string;
  iconSuffix?: string;
  tooltip?: string;
  tooltipDelay?: number;
  isShareIcon?: boolean;
}

export interface IconButton extends Button {
  icon: string;
  loadingField?: string;
  loadingColor?: string;
}
