import {
  EnergyGridConnection as EnergyGridConnectionFE,
  ProfileGroupBE,
} from 'prosumer-scenario/models';
import { HasTDBProfileFilters } from 'prosumer-shared/modules/tdb/models';

import {
  DetailEntity,
  DetailResponse,
  HasDataType,
  ScenarioDetailState,
} from '../scenario-detail';
import { YearValues as YearValuesBE } from '../scenario-generic';

export type EnergyGridConnectionState =
  ScenarioDetailState<EnergyGridConnectionInfo>;
export type EnergyGridConnectionsRsp = DetailResponse<EnergyGridConnectionBE> &
  HasDataType;
export type EnergyGridConnectionInfo = EnergyGridConnectionFE &
  DetailEntity &
  HasScenarioUuid;
export type CreateFormEGC = Omit<EnergyGridConnectionFE, 'id'>;

export interface EnergyGridConnectionBE
  extends Partial<HasDataType>,
    HasTDBProfileFilters,
    HasScenarioUuid {
  readonly profileUpdated?: boolean;
  readonly egcId?: string;
  name?: string;
  nodeId?: NodeIds | string;
  deliveryEnergyVectorId?: string;
  type?: string;
  energyTariff?: ProfileGroupBE;
  capacityTariff?: YearValuesBE | string;
  connectionTariff?: YearValuesBE | string;
  co2Rate?: string;
  scenarioVariation?: string;
  status?: string;
}

interface NodeIds {
  nodeIds?: string[];
}

interface HasScenarioUuid {
  readonly scenarioUuid?: string;
}
