export type Version = Readonly<{
  /* Git Describe Info */
  dirty?: boolean;
  raw?: string;
  hash?: string;
  distance?: string;
  tag?: string;
  semver?: string;
  suffix?: string;
  semverString?: string;
  /* Git Commit Info */
  shortHash?: string;
  commit?: string;
  shortCommit?: string;
  author?: string;
  email?: string;
  date?: string;
  message?: string;
  /* Version Info */
  version?: string;
  timestamp?: string;
}>;
