import { Observable, throwError, timer } from 'rxjs';
import { finalize, mergeMap } from 'rxjs/operators';

const DEFAULT_TIMEOUT = 1000000; // in Milliseconds
const DEFAULT_RETRY_ATTEMPTS = 2;
const DEFAULT_RETRY_SCALING_DURATION = 1000; // in Milliseconds
const DEFAULT_RETRY_STATUS_CODES = [401];
const DEFAULT_RAND_MIN = 1;
const DEFAULT_RAND_MAX = 1000;

/**
 * Returns a random value based on the min and max values
 * @param min - the start range of random value to be returned
 * @param max - the end range of random value to be returned
 */
const randomIntFromInterval = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

/**
 * Returns an exponential computation for retry attempts.
 * This helper also uses min max random number to add to the total number returned.
 * @param attempts - the retry attempt
 * @param scaling - the multiplier
 * @param min - the start range of random value to be returned (default: DEFAULT_RAND_MIN)
 * @param max - the end range of random value to be returned (default: DEFAULT_RAND_MAX)
 */
const exponentialBackoff = (
  attempts: number,
  scaling: number,
  min = DEFAULT_RAND_MIN,
  max = DEFAULT_RAND_MAX,
): number => {
  return (
    Math.round(Math.pow(2, attempts)) * scaling +
    randomIntFromInterval(min, max)
  );
};

/**
 * Check if the status code is for retry.
 * @param statusCode - the current status code to check
 * @param retryStatusCodes - the retry status codes (default: DEFAULT_RETRY_STATUS_CODES)
 */
const isStatusCodeForRetry = (
  statusCode: number,
  retryStatusCodes = DEFAULT_RETRY_STATUS_CODES,
) => {
  return retryStatusCodes?.find(
    (statusCodeItem) => statusCodeItem === statusCode,
  );
};

/**
 * The retry strategy when attempting to retry the ajax or http call.
 * @param productionMode - indicator if production mode to check if debug logs should be shown
 * @param retryAttempts - the maximum retry attempts
 * @param retryScalingDuration - the retry scaling duration used to compute for the exponential back-off
 */
const retryStrategy = (
  productionMode = false,
  retryAttempts = DEFAULT_RETRY_ATTEMPTS,
  retryScalingDuration = DEFAULT_RETRY_SCALING_DURATION,
) => {
  return () => (attempts: Observable<any>) => {
    return attempts.pipe(
      mergeMap((error, i) => {
        const retryAttempt = i + 1;

        // if maximum number of retries have been met or response' status code is not included we will not retry but throw error
        if (
          retryAttempt > retryAttempts ||
          !isStatusCodeForRetry(error.status)
        ) {
          return throwError(error);
        }
        const retryTimeout = exponentialBackoff(
          retryAttempt - 1,
          retryScalingDuration,
        );
        if (!productionMode) {
          console.log(
            `Attempt ${retryAttempt}: retrying in ${retryTimeout / 1000}s`,
          );
        }
        return timer(retryTimeout);
      }),
      finalize(() => {
        if (!productionMode) {
          console.log(`Done retrying...`);
        }
      }),
    );
  };
};

export const retryUtil = {
  DEFAULT_TIMEOUT,
  DEFAULT_RETRY_ATTEMPTS,
  DEFAULT_RETRY_SCALING_DURATION,
  DEFAULT_RETRY_STATUS_CODES,
  DEFAULT_RAND_MIN,
  DEFAULT_RAND_MAX,
  randomIntFromInterval,
  exponentialBackoff,
  isStatusCodeForRetry,
  retryStrategy,
};
