import { YearlyValues } from 'prosumer-app/shared/models';
import { YearValues } from 'prosumer-app/stores/scenario-generic';

export type BEModel = YearValues;
export type FormModel = YearlyValues;

type PossibleEmissionType = BEModel | FormModel;

interface CommoditiesEmissions<T = PossibleEmissionType> {
  readonly co2Price: T;
  readonly co2Scope2Price: T;
  readonly co2Scope3Price: T;
}

export type OutgoingEmissions = Partial<CommoditiesEmissions<BEModel>>;
export type IncomingEmissions = CommoditiesEmissions<FormModel>;
