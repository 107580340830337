import { ProfileType } from 'prosumer-app/+scenario/types';
import { DialogData, FormFieldOption } from 'prosumer-app/libs/eyes-shared';
import { YearlyValues } from 'prosumer-app/shared';
import { LineInfo } from 'prosumer-app/stores/line';

export type LinesFormDialogMode = 'add' | 'edit';

export interface LinesFormDialogData extends DialogData {
  name: string;
  energyVectorId: string;
  originNodeId: string;
  destinationNodeId: string;
  distance: number;
  bidirectional: boolean;
  forcedInvestment: boolean;
  bidirectionalOptions: Array<FormFieldOption<any>>;
  lineData?: LineInfo;
  mode: LinesFormDialogMode;
  isViewOnly?: boolean;
  sourceType?: ProfileType;
  profileOptions?: Array<FormFieldOption<ProfileType>>;
  // advanced input
  yearlyEfficiency?: YearlyValues;
  yearlyBuildEmissionsKwm?: YearlyValues;
  yearlyBuildEmissionsIndivisible?: YearlyValues;
  yearlyMinSize?: YearlyValues;
  yearlyMaxSize?: YearlyValues;
  yearlyBuildCost?: YearlyValues;
  yearlyIndivisibleCost?: YearlyValues;
  yearlyFomCharge?: YearlyValues;
  yearlyFomInstall?: YearlyValues;
  yearlyTechnicalLife?: YearlyValues;
  yearlyDepreciationTime?: YearlyValues;
  startYear?: number;
  endYear?: number;
}
