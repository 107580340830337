import { ModuleWithProviders, NgModule } from '@angular/core';
import { WebSocketConfig } from '@oculus/utils/models';
import {
  ENV_PRODUCTION,
  WEB_SOCKET_CONFIG,
  WEB_SOCKET_URL,
  WS_CONNECTION_TTL,
} from '@oculus/utils/tokens';

import { AuthStateService } from '../auth-state';
import { WSNotificationService } from './ws-notification.service';

@NgModule({})
export class WSNotificationModule {
  static forRoot(
    wsConfig: WebSocketConfig,
    production: boolean,
  ): ModuleWithProviders<WSNotificationModule> {
    return {
      ngModule: WSNotificationModule,
      providers: [
        WSNotificationService,
        AuthStateService,
        {
          provide: WEB_SOCKET_CONFIG,
          useValue: wsConfig,
        },
        {
          provide: WEB_SOCKET_URL,
          useValue: wsConfig.url,
        },
        {
          provide: WS_CONNECTION_TTL,
          useValue: wsConfig.ttlConnection,
        },
        {
          provide: ENV_PRODUCTION,
          useValue: production,
        },
      ],
    };
  }
}
