import { Params } from '@angular/router';

export interface Breadcrumb {
  label?: string;
  params?: Params;
  url?: string;
  reference?: boolean;
  referenceKey?: string;
  trim?: string;
  trimPath?: string;
  component?: any;
  forceCurrentUrl?: boolean;
}
