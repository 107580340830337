import { animate, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  TemplateRef,
} from '@angular/core';
import { formatLabel } from '@swimlane/ngx-charts';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'g[ngx-combo-charts-series-vertical]',
  template: `
    <svg:g
      ngx-charts-bar
      *ngFor="let bar of bars; trackBy: trackBy"
      [@animationState]="'active'"
      [width]="customizeWidth(bar.width)"
      [height]="bar.height"
      [x]="bar.x"
      [y]="bar.y"
      [fill]="getColor(bar)"
      [stops]="bar.gradientStops"
      [data]="bar.data"
      [orientation]="'vertical'"
      [roundEdges]="bar.roundEdges"
      [gradient]="gradient"
      [animations]="animations"
    ></svg:g>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('animationState', [
      transition('* => void', [
        style({
          opacity: 1,
          transform: '*',
        }),
        animate(500, style({ opacity: 0, transform: 'scale(0)' })),
      ]),
    ]),
  ],
})
export class ComboSeriesVerticalComponent implements OnChanges {
  customColor = '#CAC76B';
  @Input() dims;
  @Input() type = 'standard';
  @Input() series;
  @Input() xScale;
  @Input() yScale;
  @Input() colors;
  @Input() gradient: boolean;
  @Input() seriesName: string;
  @Input() animations = true;
  @Input() noBarWhenZero = true;
  @Input() tooltipTemplate: TemplateRef<any>;
  @Input() xAxisTickFormatting: any;

  @Output() bandwidth = new EventEmitter();

  bars: any;
  x: any;
  y: any;

  /**
   * Calls the update function on changes.
   *
   * @param changes - series data
   */
  ngOnChanges(changes): void {
    this.update();
  }

  /**
   * Customize the bar color for charge and discharge values
   *
   * @param bar - bar series data
   */
  getColor(bar: any) {
    if (bar.value < 0) {
      return this.customColor;
    }
    return bar.color;
  }

  /**
   * Limit the size of bars for when data is too much.
   * Minimum would be 2.
   *
   * @param width - bar width
   */
  customizeWidth(width) {
    if (width < 3) {
      return 2;
    }
    return width;
  }

  /**
   * Updates the bar property
   */
  update(): void {
    let width;
    if (this.series.length) {
      width = this.xScale.bandwidth();
      this.bandwidth.emit(width);
    }

    this.bars = this.series.map((d, index) => {
      const value = d.value;
      const label = d.name;
      const formattedLabel = this.xAxisTickFormatting
        ? this.xAxisTickFormatting(label)
        : formatLabel(label);
      const roundEdges = this.type === 'standard';

      const bar: any = {
        value,
        label,
        roundEdges,
        data: d,
        width,
        formattedLabel,
        height: 0,
        x: 0,
        y: 0,
      };
      bar.height = Math.abs(this.yScale(value) - this.yScale(0));
      bar.x = this.xScale(label);
      // defines the bar whether it's above or below x-axis
      if (value < 0) {
        bar.y = this.yScale(0);
      } else {
        bar.y = this.yScale(value);
      }

      bar.color = this.colors.getColor(label);

      return bar;
    });
  }

  /**
   * Returns bar label
   *
   * @param bar - bar data
   */
  trackBy(bar: any): string {
    return bar.label;
  }
}
