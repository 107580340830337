export interface Feature {
  name: string;
  path: string;

  background?: string;
  state?: string;
  title?: string;

  preload?: boolean;
  sideNav?: boolean;
  sideNavLink?: boolean;
  toolbarLink?: boolean;

  breadcrumb?: string;
  mode?: string;

  nestedItems?: Feature[];

  icon?: string;
}

export interface Features {
  [key: string]: Feature;
}
