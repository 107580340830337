import { BehaviorSubject } from 'rxjs';

import { Injectable } from '@angular/core';
import { Entity } from '@oculus/utils/models';

@Injectable()
export class ManagedDataService<T extends Entity = Entity> {
  data$ = new BehaviorSubject<Array<T>>([]);
  set data(value: Array<T> | undefined) {
    this.data$.next(value ?? []);
  }
  get data() {
    return this.data$.value;
  }

  add(datum: T) {
    this.data = [{ ...datum }, ...(this.data ?? [])];
  }

  edit(id: Entity['id'], datum: Partial<T>) {
    this.data = [
      {
        ...(this.data?.find((datum) => datum.id === id) || {}),
        ...datum,
      } as T,
      ...(this.data?.filter((datum) => datum.id !== id) || []),
    ];
  }

  delete(id: Entity['id']) {
    this.data = [...(this.data?.filter((datum) => datum.id !== id) || [])];
  }

  clear() {
    this.data = [];
  }
}
