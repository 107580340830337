import { RouteProfileBE, Routes } from 'prosumer-app/+scenario/models';
import { DetailEntity, ScenarioDetailState } from '../scenario-detail';

export type MobilityRoute = Routes & DetailEntity;
export type MobilityRouteForm = MobilityRoute;
export interface MobilityRouteBE {
  readonly id: string;
  readonly name: string;
  readonly profiles: RouteProfileBE[];
}

export type MobilityRouteState = ScenarioDetailState<MobilityRoute>;
