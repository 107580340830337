import { ThemePalette } from '@angular/material/core';
import { EventEmitter } from '@angular/core';

export type InputIcon = Readonly<{
  icon: string;

  inline?: boolean;
  class?: string;
  click?: EventEmitter<any>;
  color?: ThemePalette;
}>;

export type InputErrorMessageMap = Readonly<{
  [module: string]: { [errorKey: string]: string };
}>;
