import { UserInfo } from 'prosumer-app/libs/eyes-shared';

export type AuthData = Readonly<{
  idJwtToken: string;
  accessJwtToken: string;
  refreshToken: string;
  userInfo: UserInfo;
}>;

export type AuthState = Readonly<{
  authenticated: boolean;
  loading: boolean;
  data?: AuthData;
  error?: Error;
  redirectUrl?: string;
}>;
