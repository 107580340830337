import { ModuleWithProviders, NgModule } from '@angular/core';
import {
  TRANSLOCO_CONFIG,
  TRANSLOCO_LOADER,
  translocoConfig,
  TranslocoConfig,
  TranslocoModule,
} from '@ngneat/transloco';
import { ENV_PRODUCTION } from '@oculus/utils/tokens';

import { TranslocoHttpLoader } from './transloco-http-loader.service';

const factoryFn = (production: boolean) => ({
  availableLangs: ['en'],
  defaultLang: 'en',
  // Remove this option if your application doesn't support changing language in runtime.
  reRenderOnLangChange: true,
  prodMode: production,
  missingHandler: {
    allowEmpty: true,
  },
});

@NgModule({
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      deps: [ENV_PRODUCTION],
      useFactory: (production: boolean) =>
        translocoConfig(factoryFn(production)),
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
  ],
})
export class TranslocoRootModule {
  static forRoot(
    config?: Partial<TranslocoConfig>,
  ): ModuleWithProviders<TranslocoRootModule> {
    return {
      ngModule: TranslocoRootModule,
      providers: [
        {
          provide: TRANSLOCO_CONFIG,
          deps: [ENV_PRODUCTION],
          useFactory: (production: boolean) => {
            return translocoConfig({
              ...factoryFn(production),
              ...config,
            });
          },
        },
      ],
    };
  }
}
