export type MessageState = Readonly<{
  loading?: boolean;
  error?: any;
  // All listable values here?
  currentScenarioID?: string;
  data?: any;
  messages?: any;
  version?: string;
  isImportMessage?: boolean;
}>;
