import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { SentryConfig } from '@oculus/utils/models';
import { SENTRY_CONFIG } from '@oculus/utils/tokens';
import * as Sentry from '@sentry/browser';

@Injectable()
export class SentryService implements ErrorHandler {
  constructor(@Inject(SENTRY_CONFIG) config: SentryConfig) {
    if (!config.dsn.length) {
      return;
    }

    Sentry.init({
      dsn: config.dsn,
    });
  }

  handleError(error: unknown): void {
    Sentry.captureException(error);
  }
}
