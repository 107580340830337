import { Equipment } from 'prosumer-app/+scenario/models';
import { DetailEntity } from 'prosumer-app/stores';

export interface HasEquipmentId {
  readonly equipmentId?: string;
}

export interface EquipmentInfo extends Equipment, DetailEntity, HasEquipmentId {
  readonly scenarioUuid?: string;
  readonly cascadeEnergyVectorId?: string;
  readonly cascadeEquipmentId?: string;
}
