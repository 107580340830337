import {
  SimpleSnackBar,
  MatSnackBarRef,
} from '@angular/material/snack-bar';

export interface SnackbarService {
  show(
    message: string,
    action?: string,
    panelClass?: string | string[],
    duration?: number,
  ): MatSnackBarRef<SimpleSnackBar>;
  showSuccess(
    message: string,
    action?: string,
    duration?: number,
  ): MatSnackBarRef<SimpleSnackBar>;
  showError(
    message: string,
    action?: string,
    duration?: number,
  ): MatSnackBarRef<SimpleSnackBar>;
  showInfo(
    message: string,
    action?: string,
    duration?: number,
  ): MatSnackBarRef<SimpleSnackBar>;
}
