import { BaseEntity } from 'prosumer-app/libs/eyes-shared';

export interface ScenarioEntity extends BaseEntity {
  readonly copying?: boolean;
  readonly downloading?: DownloadEntity;
  readonly uploading?: boolean;
  readonly simulating?: boolean;
}

export type DownloadEntity = 'input' | 'output' | 'log';
