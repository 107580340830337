import { BinaryData, BinaryDelete, Profile } from './binary-data.model';

export interface Load extends BinaryData {
  readonly id?: string;
  readonly name?: string;
  readonly energyVector?: string;
  readonly nodes?: Array<string>;
  readonly profiles?: Array<Profile>;
  // scenarioVariationId
  readonly scenarioVariation?: string;
}

export interface LoadsForm extends BinaryDelete {
  readonly loads?: Array<Load>;
}
