import {
  ScenarioFacadeService,
  UploadingEntitiesStatusI,
  UploadingStatusEnum,
} from 'prosumer-app/+scenario/state';
import { PipeUtils } from 'prosumer-app/core';
import { EditableCellChangeI, NameValidator } from 'prosumer-app/shared';
import { ScenarioStore } from 'prosumer-app/stores/scenario';
import { debounceTime, filter, tap } from 'rxjs';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import { FormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Scenario } from '../../models';

const DEFAULT_FILE_EXTS = '.xls,.xlsx';
const DEFAULT_SCENARIO_TYPE = 'created_by_upload';
const VALID_NAME_PATTERN = /[^a-zA-Z0-9\s\-_]+/;
const MAX_NAME_LENGHT = 63;

interface ScenarioInputTableData {
  scenarioName: string;
  fileName: string;
  statusIcon?: UploadingStatusEnum;
}

@UntilDestroy()
@Component({
  selector: 'prosumer-scenario-upload-form',
  templateUrl: './scenario-upload-form.component.html',
  styleUrls: ['./scenario-upload-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScenarioUploadFormComponent implements OnInit {
  @Output() allUploadsSucceeded = new EventEmitter<void>();
  @Output() cancelClicked = new EventEmitter<void>();
  @Output() save = new EventEmitter<Scenario>();
  @Input() isLoading = false;
  @Input() isFormSubmitted = false;
  @Input() columnDef: Record<string, Record<string, string>>;

  readonly allowedFileExtns = DEFAULT_FILE_EXTS;
  readonly tableData = signal<ScenarioInputTableData[]>([]);
  readonly uploadForm = new FormArray<any>([], [Validators.required]);

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly scenarioStoreNgrx: ScenarioStore,
    private readonly scenarioFacade: ScenarioFacadeService,
  ) {}

  ngOnInit(): void {
    this.subToFormChanges();
    this.subToUploadingStatus();
  }

  private subToUploadingStatus() {
    this.scenarioFacade.uploadingEntitiesStatus$
      .pipe(
        PipeUtils.filterOutUndefined,
        debounceTime(200),
        tap((d) => this.injectUploadStatusToTableEntities(d)),
        filter(this.allSucceededUploads),
        tap(() => this.emitAllUploadsSucceeded()),
        untilDestroyed(this),
      )
      .subscribe();
  }

  onSubmit(): void {
    if (this.uploadForm.valid) this.save.emit(this.uploadForm.value);
  }

  onCancelClicked() {
    this.cancelClicked.emit();
  }

  getXlsxlTemplate(): void {
    this.scenarioStoreNgrx.downloadXlsxlTemplate('getXlsxTemplate');
  }

  inputFilesChanged(files: File[]) {
    if (files.length > this.tableData().length) {
      const nFiles = this.determineNewFiles(files, this.tableData());
      this.handleNewFiles(nFiles);
    } else if (files.length < this.tableData().length) {
      const rmData = this.determineRmFiles(files, this.tableData());
      this.handleRmFiles(rmData, this.uploadForm);
    }
  }

  onEditableCellChange(e: EditableCellChangeI) {
    const idx = this.findIdxOfAffectedCtrl(e);
    this.updateNameOfAffectedCtrl(e, idx);
  }

  private findIdxOfAffectedCtrl(e: EditableCellChangeI): number {
    return this.uploadForm.controls.findIndex(
      (ctrl) => ctrl.get('name')!.value === e.oldValue,
    );
  }

  private updateNameOfAffectedCtrl(e: EditableCellChangeI, i: number) {
    if (i < 0) return;
    this.uploadForm.at(i).get('name')!.patchValue(e.newValue);
  }

  private createGroupCtrl(f: File, scenarioName: string) {
    return this.fb.group({
      name: [
        scenarioName,
        [
          Validators.required,
          NameValidator.invalidCharacters(VALID_NAME_PATTERN),
          NameValidator.maxCharLength(MAX_NAME_LENGHT),
          NameValidator.whiteSpaceOnly(),
        ],
      ],
      description: '',
      scenarioType: DEFAULT_SCENARIO_TYPE,
      fileInput: f,
    });
  }

  private subToFormChanges() {
    this.uploadForm.valueChanges.pipe(untilDestroyed(this)).subscribe((v) => {
      this.tableData.set(this.mapFormValuesToTable(v));
    });
  }

  private mapFormValuesToTable(
    list: Record<string, any>[],
  ): ScenarioInputTableData[] {
    return list.map((v) => this.mapValueToRow(v));
  }

  private mapValueToRow(v: Record<string, any>): ScenarioInputTableData {
    return {
      fileName: v.fileInput.name,
      scenarioName: v.name,
    };
  }

  private determineNewFiles(
    fs: File[],
    existingData: ScenarioInputTableData[],
  ): File[] {
    return fs.filter((f) => !existingData.some((d) => d.fileName === f.name));
  }

  private handleNewFiles(fs: File[]) {
    fs.forEach((f) => {
      const scenarioName = f?.name ? f.name.replace(/(?:.xlsx?)$/gi, '') : '';
      const upload = this.createGroupCtrl(f, scenarioName);
      this.uploadForm.push(upload);
    });
  }

  private determineRmFiles(
    fs: File[],
    existingData: ScenarioInputTableData[],
  ): ScenarioInputTableData[] {
    return existingData.filter((d) => !fs.some((f) => d.fileName === f.name));
  }

  private handleRmFiles(
    rmData: ScenarioInputTableData[],
    formArray: FormArray<any>,
  ) {
    rmData.forEach((d) => {
      const idx = formArray.controls.findIndex(
        (ctrl) => ctrl.get('fileInput')!.value['name'] === d.fileName,
      );
      if (idx >= 0) formArray.removeAt(idx);
    });
  }

  private injectUploadStatusToTableEntities(d: UploadingEntitiesStatusI) {
    this.tableData.update((list) =>
      list.map((e) =>
        e.scenarioName ? { ...e, statusIcon: d[e.scenarioName] } : e,
      ),
    );
  }

  private allSucceededUploads(d: UploadingEntitiesStatusI): boolean {
    return Object.values(d).every((v) => v === UploadingStatusEnum.SUCCEEDED);
  }

  private emitAllUploadsSucceeded() {
    this.allUploadsSucceeded.emit();
  }
}
