import { Node, StationVehicleAssoc } from 'prosumer-app/+scenario/models';
import { DialogData, FormFieldOption } from 'prosumer-app/libs/eyes-shared';
import { Observable } from 'rxjs';

export interface VehicleStationFormDialogData
  extends StationVehicleAssoc,
    DialogData {
  mode: 'add' | 'edit';
  isMultiNode: boolean;
  isViewOnly?: boolean;
  vehicles: FormFieldOption<string>[];
  stations: FormFieldOption<string>[];
  vehicleNameOptions: FormFieldOption<string>[];
  stationNodeOptions: Node[];
  currentData: StationVehicleAssoc;
  currentAssocList: Array<StationVehicleAssoc>;
  currentDataList$?: Observable<StationVehicleAssoc[]>;
  scenarioIdentity?: {
    projectId: string;
    caseId: string;
    scenarioId: string;
  };
}
