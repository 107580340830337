import { DialogData } from 'prosumer-app/libs/eyes-shared';
import { LoadType } from 'prosumer-scenario/models';
import { YearlyInterval } from 'prosumer-shared/models/yearly-intervals.model';

export interface YearlyLoadsIntervalDialogModel extends DialogData {
  readonly startYear?: number;
  readonly endYear?: number;
  readonly intervals?: Array<YearlyInterval>;
  readonly defaultLoadType: LoadType;
  readonly defaultLocation: string;
}
