import { FormFieldOption } from 'prosumer-app/libs/eyes-shared';
import { EnergyVector } from './general.model';

export interface EnergyVectorOption extends FormFieldOption<string> {
  readonly selected?: boolean;
  readonly custom?: boolean;
  readonly type?: string;
}

export interface EnergyVectorTemporaryModel extends EnergyVector {
  isUnsaved?: boolean;
}

export interface EVPredefinedReferenceModel {
  id: string;
  value: string;
  image: string;
}
