import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

export class FileInputName {
  static invalidCharacters =
    (regex: RegExp): ValidatorFn =>
    (control: AbstractControl): ValidationErrors | null => {
      if (!!control && !!control.value) {
        return control.value.name.match(regex)
          ? { invalidCharacter: true }
          : null;
      }
    };
}
