import { DialogService } from 'prosumer-app/libs/eyes-core';
import {
  BaseDialogComponent,
  contains,
  toTitleCase,
} from 'prosumer-app/libs/eyes-shared';
import {
  cascadeFilterTaxAndSubsidiesByNetting,
  getAffectedTaxAndSubsidies,
  getUpdatedStationVehicleAssoc,
} from 'prosumer-shared';
import { Observable } from 'rxjs';

import { TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import {
  EquipmentCircuitInfo,
  EquipmentReserve,
  Metering,
  Netting,
  reserveToString,
  Scenario,
  StationVehicleAssoc,
  TaxAndSubsidies,
  VehiclesDispatch,
} from '../models';

@Injectable()
export class EquipmentCascaderService {
  constructor(
    private _dialog: DialogService,
    private _titleCase: TitleCasePipe,
    private _translate: TranslateService,
  ) {}

  // prettier-ignore
  generateAffectedMessage([
    meterings,
    scEquipments,
    reserveEquipments,
    equipmentName,
    vehicleDispatches,
    stationVehicleAssocs,
    nettings,
    taxAndSubsidies,
  ]: [
      Array<Metering>,
      Array<EquipmentCircuitInfo>,
      Array<EquipmentReserve>,
      string,
      Array<VehiclesDispatch>,
      Array<StationVehicleAssoc>,
      Array<Netting>,
      Array<TaxAndSubsidies>,
    ]) {
    const affectedMeterings = meterings.map((metering) => `<li>${toTitleCase(metering.netting)} Technology</li>`);
    const affectedScEquipments = (scEquipments || []).length > 0 ? `${scEquipments.length} short circuits` : '';
    const affectedReserveEquipments = reserveEquipments.map((reserve) => `<li>${reserveToString(reserve)}</li>`);
    // eslint-disable-next-line max-len
    const affectedVehiclesDisptach = vehicleDispatches.map((vehicle) => vehicle.vehicleName).reduce((prev, next) => prev + `<li>${next}</li>`, '');
    // eslint-disable-next-line max-len
    const affectedStationVehicleAssocs = (stationVehicleAssocs || []).length > 0 ? `${stationVehicleAssocs.length} Station/Vehicle Associations` : '';
    const affectedNettings = nettings.map((netting) => netting.name).reduce((prev, next) => prev + `<li>${next}</li>`, '');
    const affectedtaxAndSubsidies = getAffectedTaxAndSubsidies(taxAndSubsidies, nettings);

    if (affectedMeterings.length === 0 &&
      affectedScEquipments.length === 0 &&
      affectedReserveEquipments.length === 0 &&
      affectedVehiclesDisptach.length === 0 &&
      affectedStationVehicleAssocs.length === 0 &&
      affectedNettings.length === 0 &&
      affectedtaxAndSubsidies.length === 0
    ) {
      return '';
    }

    const affectedMessage =
      `<div class="list-wrapper"><b>Affected Data - ${equipmentName}</b>` +
      `${affectedMeterings.length > 0 ? `<br>Meterings:<br><ul>${affectedMeterings}</ul>` : ''}` +
      `${affectedScEquipments.length > 0 ? `<br>Short Circuit - Equipments:<br>${affectedScEquipments}` : ''}` +
      `${affectedReserveEquipments.length > 0
        ? `<br>Spinning Reserves - Reserve Equipment:<br><ul>${affectedReserveEquipments}</ul>`
        : ''
      }` +
      `${affectedVehiclesDisptach.length > 0 ? `<br>Mobility - Vehicles Dispatch:<br>${affectedVehiclesDisptach}` : ''}` +
      `${affectedStationVehicleAssocs.length > 0 ? `<br>Mobility - Station/Vehicle Association:<br>${affectedStationVehicleAssocs}` : ''}` +
      `${affectedNettings.length > 0 ? `<br>Netting:<br><ul>${affectedNettings}</ul>` : ''}` +
      `${affectedtaxAndSubsidies.length > 0 ? `<br>Tax & Subsidies:<br><ul>${affectedtaxAndSubsidies}</ul>` : ''}` +
      `</div>`;
    return affectedMessage;
  }

  generateMessage(
    base: string,
    deletedNames?: Array<string>,
    affectedMessage?: string,
  ) {
    let deletedStr = '';
    deletedNames.forEach(
      (name) => (deletedStr += `<li>${this._titleCase.transform(name)}</li>`),
    );

    return `${base}<div class="list-wrapper"><b>To Delete</b><ul>${deletedStr}</ul></div>${affectedMessage}`;
  }

  showDeletedDialog(
    deletedNodeNames: Array<string>,
    affectedMessage?: string,
  ): Observable<any> {
    return this._dialog.openDialog(BaseDialogComponent, {
      title: this._translate.instant(
        'Scenario.dialog.selectionDeleted.equipment.title',
      ),
      message: this.generateMessage(
        this._translate.instant(
          'Scenario.dialog.selectionDeleted.equipment.message',
        ),
        deletedNodeNames,
        affectedMessage,
      ),
      confirm: this._translate.instant('Generic.labels.yes'),
      close: this._translate.instant('Generic.labels.no'),
      width: 400,
    });
  }

  cascadeEquipmentDeletion(
    scenario: Scenario,
    deletedEquipmentIds: Array<string>,
  ): Scenario {
    if (!!!scenario) {
      return;
    }
    const scEquipments = (
      ((scenario || {}).frequencyControl || {}).shortCircuit || {}
    ).equipments;
    const reserveEquipments = (
      ((scenario || {}).frequencyControl || {}).spinningReserve || {}
    ).equipments;
    const vehiclesDispatch = ((scenario || {}).mobility || {}).vehicles || [];
    const stationVehicleAssocs =
      ((scenario || {}).mobility || {}).stationVehicleAssoc || [];
    const nettings = ((scenario || {}).netting || {}).netting;
    const taxAndSubsidies = ((scenario || {}).netting || {}).taxAndSubsidies;

    const cascadedMeterings = this.filterMeterings(
      ((scenario || {}).regulations || {}).meterings,
      deletedEquipmentIds,
    );
    const cascadedScEquipments = this.filterScEquipments(
      scEquipments,
      deletedEquipmentIds,
    );
    const cascadeReserveEquipments = this.filterReserveEquipments(
      reserveEquipments,
      deletedEquipmentIds,
    );
    const cascadedVehiclesDispatch = this.filterVehiclesDispatch(
      vehiclesDispatch,
      deletedEquipmentIds,
    );
    const cascadedStationVehicleAssocs = this.filterStationVehicleAssocs(
      stationVehicleAssocs,
      vehiclesDispatch,
      cascadedVehiclesDispatch,
      deletedEquipmentIds,
    );
    const cascadedNettings = this.filterNettings(nettings, deletedEquipmentIds);
    const cascadedTaxAndSubsidies = cascadeFilterTaxAndSubsidiesByNetting(
      taxAndSubsidies,
      cascadedNettings.map((netting) => netting.id),
    );

    return {
      ...scenario,
      regulations: {
        ...scenario.regulations,
        meterings: cascadedMeterings,
      },
      frequencyControl: {
        ...scenario.frequencyControl,
        shortCircuit: {
          ...scenario.frequencyControl.shortCircuit,
          equipments: cascadedScEquipments,
        },
        spinningReserve: {
          ...scenario.frequencyControl.spinningReserve,
          equipments: cascadeReserveEquipments,
        },
      },
      mobility: {
        ...scenario.mobility,
        vehicles: cascadedVehiclesDispatch,
        stationVehicleAssoc: cascadedStationVehicleAssocs,
      },
      netting: {
        netting: cascadedNettings,
        taxAndSubsidies: cascadedTaxAndSubsidies,
      },
    };
  }

  /** Return the nettings after removing the node that is part of the deletedNodeIds */
  filterNettings(nettings: Array<Netting>, deletedEquipmentIds: Array<string>) {
    if (!!!nettings) {
      return undefined;
    }
    return nettings
      .filter((netting) =>
        netting.equipments.some(
          (eqptId) => !contains(deletedEquipmentIds, eqptId),
        ),
      )
      .map((netting) => {
        const updatedItem = {
          ...netting,
          equipments: netting.equipments.filter(
            (eqptId) => !contains(deletedEquipmentIds, eqptId),
          ),
        };
        return updatedItem;
      });
  }

  filterMeterings(
    meterings: Array<Metering>,
    deletedEquipmentIds: Array<string>,
  ) {
    if (!!!meterings) {
      return meterings;
    }
    return meterings.filter(
      (metering) => !contains(deletedEquipmentIds, metering.technologyId),
    );
  }

  filterScEquipments(
    scEquipments: Array<EquipmentCircuitInfo>,
    deletedEquipmentIds: Array<string>,
  ) {
    if (!!!scEquipments) {
      return scEquipments;
    }

    return scEquipments.filter(
      (eci) => !contains(deletedEquipmentIds, eci.equipmentId),
    );
  }

  filterReserveEquipments(
    reserveEquipments: Array<EquipmentReserve>,
    deletedEquipmentIds: Array<string>,
  ) {
    if (!!!reserveEquipments) {
      return reserveEquipments;
    }
    return reserveEquipments.filter(
      (equipment) => !contains(deletedEquipmentIds, equipment.equipmentId),
    );
  }

  filterVehiclesDispatch(
    vehicles: Array<VehiclesDispatch>,
    deletedEquipmentIds: Array<string>,
  ) {
    if (!!!vehicles) {
      return vehicles;
    }
    return vehicles.filter(
      (vehicle) => !contains(deletedEquipmentIds, vehicle.vehicleId),
    );
  }

  filterStationVehicleAssocs(
    stationVehicleAssocs: Array<StationVehicleAssoc>,
    currentVehicles: Array<VehiclesDispatch>,
    remainingVehicles: Array<VehiclesDispatch>,
    deletedEquipmentIds: Array<string>,
  ) {
    if (!!!stationVehicleAssocs) {
      return stationVehicleAssocs;
    }

    const remainingVehiclesIds = remainingVehicles.map((vehicle) => vehicle.id);
    const updatedAssoc: StationVehicleAssoc[] = getUpdatedStationVehicleAssoc(
      stationVehicleAssocs,
      currentVehicles,
      remainingVehicles,
    );

    return updatedAssoc
      .filter(
        (assoc) =>
          assoc.vehicleNames.filter(
            (vehicleName) => !contains(remainingVehiclesIds, vehicleName),
          ).length === 0,
      )
      .filter(
        (assoc) =>
          !contains(deletedEquipmentIds, assoc.vehicleId) &&
          !contains(deletedEquipmentIds, assoc.stationId),
      );
  }
}
