import { BackendEntities, BackendEntity } from "./backend-entity.model";
import { KeysOf } from "./type-generics";

export const WebsocketCommands = {
  Connection: 'connection',
} as const;
export type WebsocketCommands = KeysOf<typeof WebsocketCommands>;

export type WSMessage = Readonly<{
  action?: 'keep';
  type?: string;
  command: WebsocketCommands;
  data: BackendEntity;
  users?: BackendEntities;
}>;