import { InjectionToken } from '@angular/core';

const DATE_FORMAT = 'dd/MM/yyyy';
const DATE_TIME_FORMAT = 'dd/MM/yyyy HH:mm';

export const DEFAULT_DATE_FORMAT = new InjectionToken<string>(
  'default-date-format',
  {
    factory: () => DATE_FORMAT,
  },
);

export const DEFAULT_DATE_TIME_FORMAT = new InjectionToken<string>(
  'default-date-time-format',
  {
    factory: () => DATE_TIME_FORMAT,
  },
);
