import { Scenario, ScenarioVariationMap } from 'prosumer-app/+scenario/models';
import { DialogData } from 'prosumer-app/libs/eyes-shared';

export interface CompareDialogModel extends DialogData {
  readonly scenarioOptions: Array<Scenario>;
}

export interface CompareDialogResult {
  selectedScenarios: Array<Scenario & ScenarioVariationMap>;
  referenceId: string;
}
