<ngx-charts-pie-chart
  [view]="view"
  [scheme]="chartScheme$ | async"
  [results]="filteredData$ | async"
  [legend]="showLegend"
  [legendPosition]="legendPosition"
  [labels]="true"
  (select)="onSelect($event)"
>
</ngx-charts-pie-chart>
