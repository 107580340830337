export interface StatusIconThing {
  readonly class: string;
  readonly icon: string;
  readonly label: string;
}

export type DuplicateDialogOut = Readonly<{
  duplicateName: string;
  projectId: string;
  caseId: string;
}>;

export type ClientUserPrefs = Readonly<{
  prosumerRole: string;
}>;
