import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';

export const DEFAULT_SNACKBAR_CONFIG: MatSnackBarConfig = {
  politeness: 'assertive',
  panelClass: 'center',
};

@Injectable()
export class SnackbarService {
  private readonly config: MatSnackBarConfig = {
    duration: 2000,
    horizontalPosition: 'center',
    verticalPosition: 'bottom',
  };

  constructor(
    private snackbar: MatSnackBar,
    private transloco: TranslocoService,
  ) {}

  openFromComponent<T, D>(
    component: ComponentType<T>,
    config?: MatSnackBarConfig<D>,
  ): void {
    this.snackbar.openFromComponent(component, config);
  }

  private translate(message: string): string {
    return this.transloco.translate(message);
  }

  onSuccess(
    message: string,
    withActions: string | boolean,
    config?: MatSnackBarConfig,
  ): void {
    this.dismiss();
    const action = (withActions ? withActions : undefined) as string;
    this.snackbar.open(this.translate(message), action, {
      ...this.config,
      ...config,
      panelClass: [
        'oculus-snack-onsuccess',
        (config?.panelClass ?? undefined) as string,
      ],
    });
  }

  onError(
    message: string,
    withActions: string | boolean,
    config?: MatSnackBarConfig,
  ): void {
    this.dismiss();
    const action = (withActions ? withActions : undefined) as string;
    this.snackbar.open(this.translate(message), action, {
      ...this.config,
      ...config,
      panelClass: [
        'oculus-snack-onerror',
        (config?.panelClass ?? undefined) as string,
      ],
    });
  }

  dismiss(): void {
    if (this.snackbar._openedSnackBarRef) {
      this.snackbar.dismiss();
    }
  }
}
