import { ChartJSMeta } from '../chartjs.model';

export interface AreaStack {
  readonly points: number[];
  readonly label: string;
}

export interface StackedAreaData extends ChartJSMeta {
  readonly stacks: AreaStack[];
  readonly xAxisTicks: string[];
  readonly yAxisLabel?: string;
  readonly xAxisLabel?: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const EmptyStackedAreaData: StackedAreaData = {
  stacks: [],
  xAxisTicks: [],
};

export type ColorMap = { [key: string]: string };
