import {
  DetailEntity,
  ScenarioDetailType,
} from 'prosumer-app/stores/scenario-detail/scenario-detail.state';

import { AffectedEntitiesDetailResponseI } from '../store-api';

export type CascadableEntity = DetailEntity &
  Readonly<{
    type: ScenarioDetailType;
    action: CascadeAction;
    affectedEntitiesData?: Partial<AffectedEntitiesDetailResponseI>;
  }>;

export enum CascadeAction {
  update = 'update',
  delete = 'delete',
}
