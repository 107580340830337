import { DetailEntity } from '../scenario-detail';

export interface LineInfo extends DetailEntity {
  readonly scenarioUuid: string;
  readonly lineId: string;
  readonly bidirectional: boolean;
  readonly buildCost: YearValues;
  readonly buildEmissionsIndivisible: YearValues;
  readonly buildEmissionsKwm: YearValues;
  readonly capacityExpansion: boolean;
  readonly depreciationTime: YearValues;
  readonly distance: string;
  readonly efficiency: YearValues;
  readonly existingAsset: boolean;
  readonly fomCharge: YearValues;
  readonly fomInstall: YearValues;
  readonly forcedInvestment: boolean;
  readonly indivisibleCost: YearValues;
  readonly maxSize: YearValues;
  readonly minSize: YearValues;
  readonly technicalLife: YearValues;
  readonly energyVectorId: string;
  readonly libraryId: string;
  readonly library?: any; // what was this used for?
  readonly destinationNodeId: string;
  readonly originNodeId: string;
  readonly name: string;
}

export interface YearValues {
  readonly values: string[];
  readonly year: number;
}
