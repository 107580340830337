import { MobilityRoutes, Node, Routes } from 'prosumer-app/+scenario/models';
import { DialogData, FormFieldOption } from 'prosumer-app/libs/eyes-shared';
import { Observable } from 'rxjs';

export interface RoutesFormDialogData extends Routes, DialogData {
  mode: 'add' | 'edit';
  isViewOnly?: boolean;
  startYear?: number;
  endYear?: number;
  routeEditData?: MobilityRoutes;
  existingRoutesData$?: Observable<Routes[]>;
  profileRoutesLocation?: string;
  nodeOptions: Array<Node>;
  nodes$?: Observable<FormFieldOption<string>[]>;
  scenarioIdentity?: {
    projectId: string;
    caseId: string;
    scenarioId: string;
  };
}
