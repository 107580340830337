import {
  EnergyVector,
  EnergyVectorCircuitInfo,
} from 'prosumer-app/+scenario/models';
import { DialogData } from 'prosumer-app/libs/eyes-shared';
import { HasId } from 'prosumer-app/stores';
import { PowerNeeded } from 'prosumer-app/stores/power-needed';
import { Observable } from 'rxjs';

export interface SCPowerNeededFormDialogData extends DialogData {
  mode: 'add' | 'edit' | 'view';
  powerNeeded: EnergyVectorCircuitInfo & Partial<HasId>;
  energyVectors: Array<EnergyVector>;
  scPowerNeededList: Array<EnergyVectorCircuitInfo>;
  scPowerNeededList$: Observable<PowerNeeded[]>;
  startYear?: number;
  endYear?: number;
}
