import { ChartJSMeta } from '../chartjs.model';

export interface LineData extends ChartJSMeta {
  points: number[];
  ticks: string[];
}

export interface MultipleLinesData extends ChartJSMeta {
  readonly lines: SingleLine[];
  readonly xAxisName?: string;
  readonly yAxisName?: string;
}

export interface SingleLine {
  readonly values: NameValue[];
  readonly name: string;
}

export interface NameValue {
  readonly name: string;
  readonly value: number;
}
