import {
  EnergyGridConnection,
  EnergyVector,
  MarketReserve,
} from 'prosumer-app/+scenario/models';
import { DialogData } from 'prosumer-app/libs/eyes-shared';
import { HasId } from 'prosumer-app/stores/scenario-detail';

export interface ReserveMarketFormDialogData extends DialogData {
  mode: 'add' | 'edit' | 'view';
  marketOptions: Array<EnergyGridConnection>;
  currentReserveMarket: MarketReserve & Partial<HasId>;
  currentReserveMarketList: Array<MarketReserve>;
  energyVectorOptions: Array<EnergyVector>;
}
