import { Dimensions, Feature } from 'prosumer-app/libs/eyes-shared';

export interface PageState {
  background?: string;
  name?: string;
  title?: string;
  sideNavLinks?: Array<Feature>;
  toolbarLinks?: Array<Feature>;
  dimensions?: Dimensions;
}
