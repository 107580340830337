import { Fuel as FuelFE } from 'prosumer-app/+scenario';
import { DetailEntity, HasDataType } from '../scenario-detail';
import { YearValues as YearValuesBE } from '../scenario-generic';

export interface HasFuelId {
  readonly fuelId?: string;
}

export interface FuelInfo extends FuelFE, DetailEntity {
  readonly scenarioUuid?: string;
}

export type CreateFormFuel = Omit<FuelFE, 'id'>;

export interface FuelBE
  extends Partial<HasDataType>,
    HasFuelId,
    HasScenarioUuid {
  fuelEnergyVectorId: string;
  nodeId: { nodeIds: string[] };
  fuelCost: YearValuesBE;
  co2Rate: YearValuesBE;
  co2Scope3EmissionFactor: YearValuesBE;
}

interface HasScenarioUuid {
  readonly scenarioUuid?: string;
}
