/* Model initially based on renewable energy */
export interface Library {
  readonly loading?: boolean;
  readonly type: string;
  readonly id: string;
  readonly energyVector: string;
  readonly group?: string;
  readonly description?: string;
  readonly secondEnergyVector?: string;
  readonly thirdEnergyVector?: string;
  readonly country?: string;
  readonly year?: string;
  readonly scale?: string;
  readonly updatedAt?: string;
  readonly reference?: string;
  readonly capacityLoss?: string;
  readonly secondCapacityLoss?: string;
  readonly comment?: string;
  readonly source?: string;
  readonly buildCost?: string;
  readonly secondBuildCost?: string;
  readonly thirdBuildCost?: string;
  readonly indivisibleCost?: string;
  readonly additionalCost1?: string;
  readonly additionalCost2?: string;
  readonly technicalLife?: number;
  readonly depreciationTime?: number;
  readonly fOAndMCharge?: string;
  readonly fOAndMCharge2?: string;
  readonly fOAndMPerInstall?: string;
  readonly comment2?: string;
  readonly source2?: string;
  readonly stackLifetime?: number;
  readonly footprint?: string;
  readonly maxAvailableFootprint?: string;
  readonly minPower?: string;
  readonly maxPower?: string;
  readonly comment3?: string;
  readonly source3?: string;
  readonly buildEmissionsKwm?: string;
  readonly buildEmissionsIndivisible?: string;
  /* Topology Additional Fields */
  readonly efficiency?: string;
  readonly secondEfficiency?: string;
  readonly lineStorageCapacity?: string;
  readonly maxDoD?: string;
  readonly minEp?: string;
  readonly maxEp?: string;
  readonly roundtripEfficiency?: string;
  readonly chargeDischargeRateFactor?: string;
  readonly dissipationRate?: string;
  readonly agingFactor?: string;
  readonly minEnergy?: string;
  readonly maxEnergy?: string;
  readonly forcedInvestment?: boolean;
  readonly individualSize?: string;
  readonly pmin?: string;
  readonly minUpTime?: number;
  readonly minDownTime?: number;
  readonly minRunningTime?: number;
  readonly startUpLoss?: string;
  readonly idleLoss?: string;
  readonly outputEnergyVector?: string;
  readonly secondOutputEnergyVector?: string;
  readonly thirdOutputEnergyVector?: string;
  readonly efficiencyPmin?: string;
  readonly efficiencyPmax?: string;
  readonly additionalCost?: string;
  readonly additionalCostPercentCapex?: string;
  /* Loads Additional Fields */
  readonly vectorType?: string;
  readonly businessType?: string;
  readonly buildingType?: string;
  readonly buildingCategory?: string;
  readonly location?: string;
  readonly loads?: Array<string>;
  readonly runningCost?: string;

  /* Ad-Hoc (Library Store Spec) */
  readonly energyVectorsIn?: string[];
  readonly energyVectorsOut?: string[];
}

export interface LibraryLoads {
  readonly loading?: boolean;
  readonly id: string;
  readonly data: Array<string>;
  readonly location?: string;
}
