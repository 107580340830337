import { NodeViewData } from './nodes-view.model';
import { OverviewData } from './overview.model';

export type SystemVisualizationDataScenarioInfo = {
  readonly scenarioId?: string;
  readonly variationId?: string;
};

export interface YearlySysVisCoreDataModel {
  [year: string]: SystemVisualizationData | any;
}

export interface SystemVisualizationData
  extends SystemVisualizationDataScenarioInfo {
  readonly overviewData: OverviewData;
  readonly nodeViewData: NodeViewData;
}

export type YearlySystemVisualizationData =
  SystemVisualizationDataScenarioInfo &
    YearlySysVisCoreDataModel & {
      yearsList?: string[];
    };
