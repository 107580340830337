export interface Base {
  readonly id: string;
  readonly name?: string;
  readonly rawData?: { [key: string]: any };
  readonly hideable?: boolean;
  readonly hidden?: boolean;
  readonly showInfo?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Node extends Base {}

export interface Connector extends Base {
  readonly sourceId: string;
  readonly targetId: string;
}
