import { ChartJSMeta, ColorShade } from '../chartjs.model';

export interface StackedBarMeta extends ChartJSMeta {
  readonly data: BarDatum[];
  readonly axisTicks: string[];
  readonly yAxisName?: string;
  readonly xAxisName?: string;
  readonly rightYAxisName?: string;
  readonly colors?: ScenarioColor;
}

export interface BarDatum {
  readonly name: string;
  readonly values: NameValues;
  readonly group?: string;
  readonly shade?: ColorShade;
}

export type ScenarioColor = { [name: string]: string };
export type NameValues = { [name: string]: number };
